<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Category <mdb-btn @click="category_modal=true" color="success" size="sm" class="my-0"><mdb-icon  icon="plus" /></mdb-btn></h1>
            <hr>
            <mdb-row>
              <mdb-col col="12">
                <mdb-tbl responsive striped>
                  <mdb-tbl-head>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th class="text-right">Action</th>
                    </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                    <tr v-for="(cate, index) in category" :key="cate.id">
                      <th scope="row">{{index+1}}</th>
                      <td>
                        <div>{{cate.name_en}}</div>
                        <div>{{cate.name_cn}}</div>
                        <div class="custom-control custom-switch" @click="toggleActive(cate, index)">
                            <input type="checkbox" class="custom-control-input" v-model="cate.is_active">
                            <label class="custom-control-label">Active</label>
                        </div>
                      </td>
                      <td class="text-right">
                        <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                          <span slot="tip">Edit Category</span>
                          <a @click="editCategory(cate)" slot="reference">
                          <mdb-btn color="primary" size="sm"><mdb-icon far icon="edit" /></mdb-btn>
                          </a>
                        </mdb-tooltip>
                        <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                          <span slot="tip">Delete Category</span>
                          <a @click="showDeleteModal(cate)" slot="reference">
                          <mdb-btn color="danger" size="sm"><mdb-icon icon="trash" /></mdb-btn>
                          </a>
                        </mdb-tooltip>
                      </td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </mdb-col>
            </mdb-row>
        </mdb-container>
        <CategoryModal :visible="category_modal" :focus_category="focus_category" :close="closeModal" />
        <ConfirmationModal  title="Are you sure?" :visible="show_confirmation_modal" :close="closeModal" :submit="deleteProduct" />
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn, mdbTooltip, mdbIcon } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import { APP_FILE_URL } from '../js/config';
import ConfirmationModal from '../components/ConfirmationModal';
import CategoryModal from '../components/CategoryModal';

export default {
  name: 'wallet_management',
  components: {
    mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn,
    AppNavigation, mdbTooltip, mdbIcon, ConfirmationModal, CategoryModal
  },
  data: ()=>({
    loading:false,
    category: [],
    show_confirmation_modal: false,
    focus_category: null,

    category_modal: false,
  }),
  methods: {
    getData(){
      if(this.loading){
        return false;
      }
      this.loading=true;
      ajax({
        url: "admin/list_category",
        complete: ()=>this.loading=false,
        success: result=>{
          this.category=result.data;  
        },
      }, this)
    },
    getProductAvatar(avatar){
        return APP_FILE_URL+avatar;
    },
    deleteProduct(){
        ajax({
            url: "admin/delete_category",
            complete: ()=>this.loading=false,
            data: {id: this.focus_product.id},
            success: ()=>{
                this.$notify.success({message: "Category Deleted", position:'top right', timeOut: 5000});
                this.getData();
            },
        }, this)
    },
    showDeleteModal(cate){
        this.focus_category=cate;
        this.show_confirmation_modal=true;
    },
    editCategory(cate){
        this.focus_category=cate;
        this.category_modal=true;
    },
    closeModal(){
        this.show_confirmation_modal=false;
        this.category_modal=false;
        this.focus_category=null;
    },
    toggleActive(cate, index){  
        let temp=[...this.category];
        temp[index].is_active=temp[index].is_active==1?0:1;
        this.category=temp;
        ajax({
            url: "admin/toggle_category",
            complete: ()=>this.loading=false,
            data: {id: cate.id},
            success: ()=>{
                this.$notify.success({message: "Category Updated", position:'top right', timeOut: 5000});
                this.getData();
            },
        }, this)
    }
  },
  mounted: function(){
    this.getData();
  }
}
</script>
