<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Users</h1>
            <hr>
            <mdb-row>
                <mdb-col md="3">
                    <div class="form-group">
                        <input v-on:keyup.enter="searchData" v-model="search" type="text" placeholder="#Name or #Phone Number" class="form-control">
                    </div>
                </mdb-col>
                <mdb-col md="3" class="d-flex align-items-center">
                    <mdb-spinner small v-if="loading" />
                    <div v-if="!loading">Total Count: {{count}}</div>
                </mdb-col>
            </mdb-row>
            <mdb-tbl responsive hover>
                <mdb-tbl-head>
                <tr>
                    <th width="5%">#</th>
                    <th>Username/Name</th>
                    <th>Information</th>
                    <th>Referral Code</th>
                    <th>Last Login</th>
                    <th class="hover-sort" @click="changeSort">Date Time <i v-if="sort_by=='asc'" class="fas fa-arrow-up"></i><i v-if="sort_by=='desc'" class="fas fa-arrow-down"></i></th>
                    <th>Action</th>
                </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                <tr v-for="(user, index) in users" :key="user.id">
                    <th scope="row">{{index+1}}</th>
                    <td>
                        <div>{{user.username}}</div>
                        <div>
                            {{user.name}} 
                            <mdb-icon icon="male" v-if="user.gender=='male'" style="color: #4285F4; margin-left: 10px" /> 
                            <mdb-icon icon="female" v-if="user.gender=='female'" style="color: #E91E63; margin-left: 10px" />
                        </div>
                    </td>
                    <td>
                        <div v-if="user.email">
                            <mdb-icon far icon="envelope" />
                            {{user.email}}
                        </div>
                        <div v-if="user.ic">
                            <mdb-icon far icon="id-card" />
                            {{user.ic}}
                        </div>
                    </td>
                    <td>
                        {{user.referral_code}}
                    </td>
                    <td>
                        {{momentFormat(user.last_login)}}
                    </td>
                    <td>
                        <div>{{momentFormat16(user.created_at)}}</div>
                        <div v-if="user.referrer_user" class="text-primary">Referred: {{user.referrer_user.name}} ({{user.referrer_user.phone_number}})</div>
                    </td>
                    <td class="d-flex flex-column">
                        <!-- <mdb-btn v-if="checkPermission(63)" size="sm" color="primary" @click="showUserPromoCode(user)">
                            <mdb-icon icon="percentage" style="margin-right: 5px" />Promo Code
                        </mdb-btn>
                        <mdb-btn size="sm" color="success" @click="toggleAcceptCash(user, 0)" v-if="user.is_accept_cash==1">
                            <mdb-icon icon="money-bill" style="margin-right: 5px"/>Accept Cash
                        </mdb-btn>
                        <mdb-btn size="sm" color="danger" @click="toggleAcceptCash(user, 1)" v-if="user.is_accept_cash==0">
                            <mdb-icon far icon="trash-alt" style="margin-right: 5px" />No Cash
                        </mdb-btn> -->
                    </td>
                </tr>
                </mdb-tbl-body>
            </mdb-tbl>
        </mdb-container>
        <ConfirmationModal title="Confirmation Verification?" :visible="verification_confirmation" :close="closeModal" :submit="verifyUser" />
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbTbl, mdbTblHead, mdbTblBody, mdbRow, mdbCol, mdbSpinner, mdbIcon } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import scroll from '../js/infinityScroll';
import {momentFormat16, momentFormat, checkPermissionsBinary} from '../js/helpers';
import ConfirmationModal from '../components/ConfirmationModal';
// import UserPromoCode from '../components/UserPromoCode.vue';
import {validatePhone} from '../js/validation';

export default {
    name: 'users',
    components: {
        mdbContainer, mdbSpinner, mdbIcon,
        AppNavigation, mdbRow, mdbCol,
        mdbTbl, mdbTblHead, mdbTblBody, ConfirmationModal,
        
    },
    data: ()=>({
        loading:false,
        users: [],
        search: "",
        count: 0,
        sort_by: "asc",
        user_promo_code_modal: false,
        focus_user: null,
        promo_codes: [],
        verification_confirmation:false,
    }),
    methods: {
        getData(){
            if(this.loading){
                return false;
            }
            this.loading=true;
            ajax({
                url: "admin/list_users",
                data: {
                    id: this.users.length==0?null:this.users[this.users.length-1].id,
                    search: this.search,
                    sort_by: this.sort_by
                },
                complete: ()=>this.loading=false,
                success: result=>{
                    let _temp=[...this.users];
                    this.users=_temp.concat(result.data.users);
                    this.count=result.data.count;
                },
            }, this)
        },
        checkPermission(permission){
            return checkPermissionsBinary(permission, this.$store.state.permissions);
        },
        momentFormat(date){
            return momentFormat(date);
        },
        momentFormat16(date){
            return momentFormat16(date);
        },
        searchData(){
            this.users=[];
            this.getData();
        },
        changeSort(){
            this.sort_by=this.sort_by=="asc"?"desc":"asc";
            this.searchData();
        },
        closeModal(){
            this.verification_confirmation=false
            this.user_promo_code_modal=false;
        },
        showUserPromoCode(user){
            this.user_promo_code_modal=true;
            this.focus_user=user;
        },
        confirmationForVerification(user){
            if(!validatePhone(user.phone_number)){
                this.$notify.error({message: "Invalid Phone Number.", position: 'top right', timeOut: 5000})
                return false;
            }
            this.verification_confirmation=true;
            this.focus_user=user;
        },
        verifyUser(){
            this.loading=true;
            ajax({
                url: "admin/verify_user",
                data: {
                    user_id: this.focus_user.id,
                    phone_number: this.focus_user.phone_number,
                },
                complete: ()=>this.loading=false,
                success: ()=>{
                    this.focus_user.setup==1;
                    this.$notify.success({message: this.focus_user.name+' has been verified.', position:'top right', timeOut: 5000});
                },
            }, this)
        }
    },
    mounted: function(){
        this.getData();
        scroll(()=>this.getData());
    }
}
</script>

<style scoped>
.hover-sort:hover{
    cursor: pointer;
    color: #4285F4;
}
</style>
