import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { ajax, fileUploadAjax } from './js/ajax'

Vue.config.productionTip = false
const ajaxHelper = {
  install() {
    Vue.prototype.$ajax = ajax
  },
}

const fileAjaxHelper = {
  install() {
    Vue.prototype.$fileAjax = fileUploadAjax
  },
}

import Notify from 'mdbvue/lib/components/Notify'
import './js/veeValidateRules.js'
Vue.use(Notify)
Vue.use(ajaxHelper)
Vue.use(fileAjaxHelper)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
