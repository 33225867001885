<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Home Care Attributes <mdb-btn @click="attribute_modal=true" color="success" size="sm" class="my-0"><mdb-icon  icon="plus" /></mdb-btn></h1>
            <hr>
            <mdb-row>
              <mdb-col col="12">
                <mdb-tbl responsive striped>
                  <mdb-tbl-head>
                    <tr>
                      <th>#</th>
                      <th>Icon Image</th>
                      <th>Name</th>
                      <th class="text-right">Action</th>
                    </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                    <tr v-for="(record, index) in records" :key="record.id">
                      <th scope="row">{{index+1}}</th>
                      <td>
                         <div class="d-flex flex-column align-items-center black" style="height: 100px; width: 150px; border-radius: 5px;">
                            <img :src="getProductAvatar(record.image)" class="waves-effect img-fluid" style="border-radius: 5px" />
                        </div>
                      </td>
                      <td>
                        <div>{{record.attribute_en}}</div>
                        <div>{{record.attribute_cn}}</div>
                      </td>
                      <td class="text-right">
                        <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                          <span slot="tip">Edit Attribute</span>
                          <a @click="editAttribute(record)" slot="reference">
                          <mdb-btn color="primary" size="sm"><mdb-icon far icon="edit" /></mdb-btn>
                          </a>
                        </mdb-tooltip>
                        <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                          <span slot="tip">Delete Attribute</span>
                          <a @click="showDeleteModal(record)" slot="reference">
                          <mdb-btn color="danger" size="sm"><mdb-icon icon="trash" /></mdb-btn>
                          </a>
                        </mdb-tooltip>
                      </td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </mdb-col>
            </mdb-row>
        </mdb-container>
        <HomeCareAttributeModal :visible="attribute_modal" :focus_attribute="focus_attribute" :close="closeModal" />
        <ConfirmationModal  title="Are you sure?" :visible="show_confirmation_modal" :close="closeModal" :submit="deleteProduct" />
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn, mdbTooltip, mdbIcon } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import { APP_FILE_URL } from '../js/config';
import ConfirmationModal from '../components/ConfirmationModal';
import HomeCareAttributeModal from '../components/HomeCareAttributeModal.vue';

export default {
  name: 'home_care_attributes',
  components: {
    mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn,
    AppNavigation, mdbTooltip, mdbIcon, ConfirmationModal, HomeCareAttributeModal
  },
  data: ()=>({
    loading:false,
    records: [],
    show_confirmation_modal: false,
    focus_attribute: null,

    attribute_modal: false,
  }),
  methods: {
    getData(){
      if(this.loading){
        return false;
      }
      this.loading=true;
      ajax({
        url: "admin/list_attributes",
        complete: ()=>this.loading=false,
        success: result=>{
          this.records=result.data;  
        },
      }, this)
    },
    getProductAvatar(avatar){
        return APP_FILE_URL+avatar;
    },
    deleteProduct(){
        ajax({
            url: "admin/delete_attribute",
            complete: ()=>this.loading=false,
            data: {id: this.focus_product.id},
            success: ()=>{
                this.$notify.success({message: "Attribute Deleted", position:'top right', timeOut: 5000});
                this.getData();
            },
        }, this)
    },
    showDeleteModal(cate){
        this.focus_category=cate;
        this.show_confirmation_modal=true;
    },
    editAttribute(cate){
        this.focus_attribute=cate;
        this.attribute_modal=true;
    },
    closeModal(){
        this.show_confirmation_modal=false;
        this.attribute_modal=false;
        this.focus_attribute=null;
        this.getData();
    },
    toggleActive(cate, index){  
        let temp=[...this.category];
        temp[index].is_active=temp[index].is_active==1?0:1;
        this.category=temp;
        ajax({
            url: "admin/toggle_category",
            complete: ()=>this.loading=false,
            data: {id: cate.id},
            success: ()=>{
                this.$notify.success({message: "Category Updated", position:'top right', timeOut: 5000});
                this.getData();
            },
        }, this)
    }
  },
  mounted: function(){
    this.getData();
  }
}
</script>
