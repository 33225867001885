var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Dialog',{attrs:{"visible":_vm.visible,"title":_vm.title,"loading":_vm.loading,"submitLabel":"Submit"},on:{"close":_vm.closeModal,"onSubmitModal":_vm.submitAdvertismentPopup,"componentDidMount":_vm.componentDidMount}},[_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('mdb-container',[_c('mdb-view',{staticClass:"mb-4",staticStyle:{"height":"200px","width":"100%"}},[_c('div',{staticClass:"promotion-img",style:({ 'background-image': 'url(' + _vm.getImg() + ')' })}),_c('a',{on:{"click":_vm.openFileSelect}},[_c('mdb-mask',{attrs:{"overlay":"white-slight"}})],1),_c('input',{attrs:{"multiple":"","type":"file","id":"file-upload","hidden":""},on:{"change":_vm.uploadImage}}),(_vm.upload_loading)?_c('Loading'):_vm._e()],1),_c('mdb-row',[_c('mdb-col',{attrs:{"col":"6"}},[_c('validation-provider',{attrs:{"name":"Name English","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('mdb-input',{attrs:{"type":"text","label":"Name English","invalidFeedback":errors[0],"isValid":!!!errors[0],"validation":touched},model:{value:(_vm.form.name_en),callback:function ($$v) {_vm.$set(_vm.form, "name_en", $$v)},expression:"form.name_en"}})]}}])})],1),_c('mdb-col',{attrs:{"col":"6"}},[_c('validation-provider',{attrs:{"name":"Name Chinese","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('mdb-input',{attrs:{"type":"text","label":"Name Chinese","invalidFeedback":errors[0],"isValid":!!!errors[0],"validation":touched},model:{value:(_vm.form.name_cn),callback:function ($$v) {_vm.$set(_vm.form, "name_cn", $$v)},expression:"form.name_cn"}})]}}])})],1)],1),_c('mdb-row',[_c('mdb-col',{attrs:{"col":"12"}},[_c('validation-provider',{attrs:{"name":"Description English","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('mdb-input',{attrs:{"type":"text","label":"Description English","invalidFeedback":errors[0],"isValid":!!!errors[0],"validation":touched},model:{value:(_vm.form.description_en),callback:function ($$v) {_vm.$set(_vm.form, "description_en", $$v)},expression:"form.description_en"}})]}}])})],1)],1),_c('mdb-row',[_c('mdb-col',{attrs:{"col":"12"}},[_c('validation-provider',{attrs:{"name":"Description Chinese","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('mdb-input',{attrs:{"type":"text","label":"Description Chinese","invalidFeedback":errors[0],"isValid":!!!errors[0],"validation":touched},model:{value:(_vm.form.description_cn),callback:function ($$v) {_vm.$set(_vm.form, "description_cn", $$v)},expression:"form.description_cn"}})]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }