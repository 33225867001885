<template>
  <div>
    <mdb-modal :show="visible" @close="onClose">
      <mdb-modal-header>
        <mdb-modal-title>{{ title ? title : 'Are you sure?' }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body v-if="text">
        <p class="mx-5 text-center">{{ text }}</p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn @click="onSubmit" size="sm" color="primary">Confirm</mdb-btn>
        <mdb-btn @click="onClose" size="sm" color="danger">Close</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalFooter, mdbBtn, mdbModalBody } from 'mdbvue';

export default {
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalFooter,
    mdbBtn, mdbModalBody
  },
  props: ['visible', 'close', 'title', 'submit', 'text'],
  methods: {
    onSubmit() {
      this.submit();
      this.close();
    },
    onClose() {
      this.close();
    }
  },
};
</script>

<style scoped>

</style>