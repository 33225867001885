<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Active Orders <mdb-btn size="sm" @click="search" color="primary"><mdb-icon
                        icon="sync-alt" /></mdb-btn></h1>
            <hr>
            <mdb-row>
                <mdb-col md="3">
                    <div class="form-group">
                        <input v-on:keyup.enter="search" v-model="order_number" type="text" placeholder="#Order Number"
                            class="form-control">
                    </div>
                </mdb-col>
                <mdb-col md="3">
                    <select v-model="status" class="browser-default custom-select">
                        <option value="" selected>Status</option>
                        <option value="NEW">New</option>
                        <option value="COMPLETED">Completed</option>
                        <option value="CANCELLED">Cancelled</option>
                    </select>
                </mdb-col>
                <mdb-col md="3">
                    <select v-model="payment_method" class="browser-default custom-select">
                        <option value="" selected>All Payment Method</option>
                        <option value="CASH">Cash</option>
                        <option value="ONLINE_BANKING">Online Banking</option>
                        <option value="WALLET">Wallet</option>
                    </select>
                </mdb-col>
                <mdb-col md="3">
                    <select v-model="service_id" class="browser-default custom-select">
                        <option value="0">All Services</option>
                        <option value="1">Ambulance</option>
                        <option value="2">eCommerce</option>
                        <option value="3">Home Care</option>
                        <option value="4">Medication</option>
                        <option value="5">Consultation</option>
                        <option value="6">Booking</option>
                    </select>
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-col md="3">
                    <input v-on:keyup.enter="search" v-model="phone_number" type="text" placeholder="#Phone Number"
                        class="form-control">
                </mdb-col>
                <mdb-col md="3">
                    <select v-model="type" class="browser-default custom-select">
                        <option value="" selected>All Time</option>
                        <option value="PAST">Past</option>
                        <option value="TODAY">Today</option>
                        <option value="FUTURE">Future</option>
                        <option value="EXPIRED">Expired</option>
                    </select>
                </mdb-col>
            </mdb-row>
            <p class="text-danger" v-if="loading">Please Wait... Fetching Data dont panic don't spam refresh!</p>
            <mdb-tbl responsive hover>
                <mdb-tbl-head>
                    <tr>
                        <th width="5%">#</th>
                        <th width="10%">Order No.</th>
                        <th width="20%">Customer</th>
                        <th width="20%">Execution</th>
                        <th width="15%">Status</th>
                        <th width="15%">Bill Charge</th>
                        <th width="15%" class="text-right">Date Time</th>
                    </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                    <tr v-for="(order, index) in orders" :key="order.id" @click="showOrderDetail(order.id, index)"
                        style="cursor: pointer">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>
                            <div @click.prevent.stop>{{ order.order_number }} <mdb-icon class="order-number-text"
                                    @click.native="copyClipboardOrderNumber(order.order_number)" icon="paste" /></div>
                            <div class="small">
                                <img style="height: 15px; width: 15px;"
                                    :src="require(`@/assets/${order.service.id}.png`)" />
                                {{ order.service.name }}
                            </div>
                            <div class="d-flex flex-row">
                                <div v-if="order.comment_admin" class="note-remark small">Note</div>
                            </div>
                            <div @click.prevent.stop v-if="order.pickup && order.deliver"><mdb-icon
                                    class="order-number-text"
                                    @click.native="openDirectionMap(order.pickup, order.deliver)" far icon="map" />
                                Route</div>
                        </td>
                        <td>
                            <div @click.prevent.stop>{{ order.customer_user.name }} <mdb-icon class="order-number-text"
                                    @click.native="navigateToUser(order.customer_user.username)" icon="link" /></div>
                            <div>{{ order.customer_user.username }}</div>
                        </td>
                        <td>
                            <div v-if="order.scheduled_at" class="font-weight-bold"><mdb-icon far icon="clock" />
                                {{ momentFormat(order.scheduled_at) }}</div>
                        </td>
                        <td>
                            <div v-if="order.status == 'CANCELLED'" class="status-cancelled">{{ order.status }}</div>
                            <div v-if="order.status == 'NEW'" class="status-new">{{ order.status }}</div>
                            <div v-if="order.status == 'COMPLETED'" class="status-completed">{{ order.status }}</div>
                            <div class="text-center">{{ order.current_step }}</div>
                            <div class="text-center">{{ order.cancel_reason }}</div>
                            <div class="text-center">{{ momentFormat(order.time_cancel_job) }}</div>
                        </td>
                        <td class="d-flex flex-column">
                            <div class="d-flex flex-row">
                                <mdb-tooltip v-if="order.payment_method == 'ONLINE_BANKING'" trigger="hover"
                                    :options="{ placement: 'top' }">
                                    <span slot="tip">Online Banking</span>
                                    <a slot="reference">
                                        <img style="height: 15px; width: 15px;"
                                            :src="require(`@/assets/online_banking.png`)" />
                                    </a>
                                </mdb-tooltip>
                                <mdb-tooltip v-if="order.payment_method == 'CASH'" trigger="hover"
                                    :options="{ placement: 'top' }">
                                    <span slot="tip">Cash</span>
                                    <a slot="reference">
                                        <img style="height: 15px; width: 15px;" :src="require(`@/assets/cash.png`)" />
                                    </a>
                                </mdb-tooltip>
                                <mdb-tooltip v-if="order.payment_method == 'WALLET'" trigger="hover"
                                    :options="{ placement: 'top' }">
                                    <span slot="tip">Wallet</span>
                                    <a slot="reference">
                                        <img style="height: 15px; width: 15px;"
                                            :src="require(`@/assets/e_wallet.png`)" />
                                    </a>
                                </mdb-tooltip>
                                <mdb-tooltip v-if="order.payment_method == 'CARD'" trigger="hover"
                                    :options="{ placement: 'top' }">
                                    <span slot="tip">Wallet</span>
                                    <a slot="reference">
                                        <img style="height: 15px; width: 15px;" :src="require(`@/assets/card.png`)" />
                                    </a>
                                </mdb-tooltip>
                                <div class="ml-2">RM {{ order.grandtotal }}</div>
                            </div>
                        </td>
                        <td class="text-right">
                            <div>{{ momentFormat16(order.created_at) }}</div>
                            <div class="text-success">{{ momentFormat(order.time_complete) }}</div>
                            <div class="text-danger">{{ momentFormat(order.cancelled_at) }}</div>
                        </td>
                    </tr>
                </mdb-tbl-body>
            </mdb-tbl>
            <OrderDetail :id="focus_order_id" :visible="order_detail_component" :close="closeOrderDetailComponent"
                :callback="callback" />
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {
    mdbContainer, mdbTbl, mdbTblHead, mdbTblBody, mdbRow, mdbCol,
    mdbTooltip, mdbIcon, mdbBtn
} from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import { ajax } from '../js/ajax';
// import {APP_FILE_URL} from '../js/config';
import moment from 'moment';
import scroll from '../js/infinityScroll';
import OrderDetail from '../components/OrderDetail';

export default {
    name: 'active_orders',
    components: {
        mdbContainer, mdbRow, mdbCol, mdbBtn,
        AppNavigation, mdbTbl, mdbTblHead, mdbTblBody,
        mdbTooltip, mdbIcon, OrderDetail
    },
    data: () => ({
        loading: false,
        orders: [],
        order_number: "",
        type: "",
        status: "",
        payment_method: "",
        phone_number: "",
        service_id: "0",

        order_detail_component: false,
        focus_order_id: 0,
        focus_index: 0
    }),
    methods: {
        getData() {
            if (this.loading) {
                return false;
            }
            this.loading = true;
            ajax({
                url: "admin/get_orders",
                data: {
                    id: this.orders.length == 0 ? null : this.orders[this.orders.length - 1].id,
                    order_number: this.order_number,
                    status: this.status,
                    payment_method: this.payment_method,
                    phone_number: this.phone_number,
                    service_id: this.service_id,
                    type: this.type
                },
                complete: () => this.loading = false,
                success: result => {
                    let _temp = [...this.orders];
                    this.orders = _temp.concat(result.data.orders);
                },
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        },
        search() {
            this.orders = [];
            this.getData();
        },
        closeOrderDetailComponent() {
            this.order_detail_component = false;
            this.focus_order_id = 0;
            this.focus_index = 0;
        },
        callback(service_order = null) {
            if (service_order == null) {
                this.search();
            } else {
                let orders = [...this.orders];
                orders[this.focus_index] = service_order;
                this.orders = orders;
            }
        },
        momentFormat16(date) {
            if (date) {
                return moment(date, "YYYY-MM-DD h:mm:ss").utcOffset('+16:00').format('DD/MM/YYYY h:mm a');
            }
            return "";
        },
        momentFormat(date) {
            if (date) {
                return moment(date, "YYYY-MM-DD h:mm:ss").format('DD/MM/YYYY h:mm a');
            }
            return "";
        },
        copyClipboardOrderNumber(order_number) {
            let textArea = document.querySelector('#copy-text')
            textArea.value = order_number;
            textArea.select();
            document.execCommand('copy');
            this.$notify.success({ message: "Order Number Copied", position: 'top right', timeOut: 5000 });
        },
        openDirectionMap(pickup, destination) {
            let url = `https://www.google.com/maps/dir/?api=1&origin=${pickup.lat},${pickup.lng}&destination=${destination.lat},${destination.lng}`;
            window.open(url);
        },
        showOrderDetail(id, index) {
            this.focus_order_id = id;
            this.focus_index = index;
            this.order_detail_component = true;
        },
    },
    mounted: function () {
        this.getData();
        scroll(() => this.getData());
    },
    watch: {
        status() {
            this.search();
        },
        payment_method() {
            this.search();
        },
        service_id() {
            this.search();
        },
        type() {
            this.search();
        }
    }
}
</script>

<style scoped>
.status-cancelled {
    border: 1.5px solid #ff4444;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff4444;
}

.status-completed {
    border: 1.5px solid #00C851;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00C851;
}

.status-new {
    border: 1.5px solid #4285F4;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4285F4;
}

#copy-text {
    position: absolute;
    top: -9999999999px;
    left: -9999999999px;
}

.order-number-text:hover {
    color: #4285F4;
    font-weight: bold;
}

.order-number-text {
    font-weight: bold;
}

.pb-remark {
    background-color: #E91E63;
    color: #fff;
    width: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 10px;
    margin: 0px 5px 0px 00px;
}

.mart-remark {
    background-color: #4caf50;
    color: #fff;
    width: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 30px;
    margin: 0px 5px 0px 00px;
}

.note-remark {
    background-color: #ffeb3b;
    color: #000;
    width: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 30px;
    margin: 0px 5px 0px 00px;
}
</style>