<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Wallet Management</h1>
            <hr>
            <mdb-row>
              <mdb-col col="12">
                <mdb-tbl responsive striped>
                  <mdb-tbl-head>
                    <tr>
                      <th>#</th>
                      <th>User</th>
                      <th>Wallet Number</th>
                      <th class="text-right">Balance (RM)</th>
                      <th class="text-right">Action</th>
                    </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                    <tr v-for="(wallet, index) in wallets" :key="wallet.id">
                      <th scope="row">{{index+1}}</th>
                      <td>
                        <div>{{wallet.user.name}}</div>
                        <div>{{wallet.user.username}}</div>
                      </td>
                      <td>
                        <div>{{wallet.wallet_number}}</div>
                      </td>
                      <td class="text-right">
                        <div class="font-weight-bold">{{wallet.balance}}</div>
                        <div>{{wallet.hold_amount}}</div>
                      </td>
                      <td class="text-right">
                        <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                          <span slot="tip">View Transactions</span>
                          <a slot="reference">
                          <mdb-btn @click="$router.push({ name: 'wallet_transactions', params: {id: wallet.id} })" color="primary" size="sm"><mdb-icon far icon="eye" /></mdb-btn>
                          </a>
                        </mdb-tooltip>
                        <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                          <span slot="tip">Manage Wallet</span>
                          <a slot="reference">
                          <mdb-btn color="success" @click="manageWallet(wallet)" size="sm"><mdb-icon icon="wallet" /></mdb-btn>
                          </a>
                        </mdb-tooltip>
                      </td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </mdb-col>
            </mdb-row>
        </mdb-container>
        <ManageWallet :visible="manage_wallet_visible" :close="closeComponents" :callback="getData" :wallet="focus_wallet" />
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn, mdbTooltip, mdbIcon } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import scroll from '../js/infinityScroll';
import ManageWallet from '../components/ManageWallet'

export default {
  name: 'wallet_management',
  components: {
    mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn,
    AppNavigation, mdbTooltip, mdbIcon, ManageWallet
  },
  data: ()=>({
    loading:false,
    wallets: [],
    manage_wallet_visible: false,
    focus_wallet: null
  }),
  methods: {
    resetGetData(){
      this.wallets=[];
      this.getData();
    },  
    getData(){
      if(this.loading){
        return false;
      }
      this.loading=true;
      ajax({
        url: "admin/get_user_wallets",
        complete: ()=>this.loading=false,
        data: {id: this.wallets.length==0?null:this.wallets[this.wallets.length-1].id},
        success: result=>{
          let _temp=[...this.wallets];
          this.wallets=_temp.concat(result.data);      
        },
      }, this)
    },
    manageWallet(wallet){
      this.focus_wallet=wallet;
      this.manage_wallet_visible=true;
    },
    closeComponents(){
      this.manage_wallet_visible=false;
    },
  },
  mounted: function(){
    this.getData();
    scroll(()=>this.getData());
  }
}
</script>
