<template>
    <AppNavigation>
        <mdb-container fluid>
            <mdb-row class="mb-3">
                <mdb-col col="1" v-if="this.$route.params.id" class="d-flex flex-center">
                    <mdb-btn @click="$router.back()" size="sm" color="primary"><i class="fas fa-arrow-left"></i></mdb-btn>
                </mdb-col>
                <mdb-col col="11">
                    <h1 class="h1-responsive">Wallet Transactions</h1>
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-col md="2">
                    <div class="form-group">
                        <mdb-date-picker :autoHide="true" v-model="date_from" label="Date From" />
                    </div>
                </mdb-col>
                <mdb-col md="2">
                    <div class="form-group">
                        <mdb-date-picker :autoHide="true"  v-model="date_to" label="Date To" />
                    </div>
                </mdb-col>
                <mdb-col md="3" class="d-flex align-items-center">
                    <select v-model="service_type_select" class="browser-default custom-select">
                        <option value="">Service Type</option>
                        <option value="TRANSFER">Transfer</option>
                        <option value="TOPUP">Top Up</option>
                        <option value="REFUND">Refund</option>
                        <option value="WITHDRAW">Withdraw</option>
                        <option value="PAYMENT">Payment</option>
                        <option value="ADMIN_TOPUP">Admin Top Up</option>
                        <option value="ADMIN_WITHDRAW">Admin Withdraw</option>
                    </select>
                </mdb-col>
                <mdb-col md="2" class="d-flex align-items-center">
                    <mdb-btn @click="getData()" v-if="!loading" outline="primary" size="sm"><mdb-icon icon="search" /> Search</mdb-btn>
                    <mdb-spinner small v-if="loading" />
                </mdb-col>
            </mdb-row>
            <hr>
            <mdb-row>
            </mdb-row>
            <div v-if="loading">Wait.. Processing dont panic...</div>
            <mdb-tbl responsive striped v-if="!loading">
                <mdb-tbl-head>
                <tr>
                    <th width="5%">#</th>
                    <th width="15%"><div>Ref Number</div></th>
                    <th width="15%"><div>Order Number</div></th>
                    <th width="15%"><div>Service Type</div></th>
                    <th width="15%"><div>Amount</div></th>
                    <th width="15%"><div>Status</div></th>
                    <th width="15%"><div>Date Time</div></th>
                    <th>Action</th>
                </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                <tr style="cursor: pointer" v-for="(record, index) in records" :key="record.id">
                    <td>{{index+1}}</td>
                    <td>
                        <div class="font-weight-bold">{{record.ref}}</div>
                        <div class="text-danger" style="font-size: 0.8rem">{{record.remarks}}</div>
                    </td>
                    <td>
                        <div v-if="record.service_order">{{record.service_order.order_number}}</div>
                        <div v-if="record.transaction_id">{{record.transaction_id}}</div>
                    </td>
                    <td>
                        <div>{{record.service_type}}</div>
                        <div v-if="record.admin">{{record.admin.username}}</div>
                    </td>
                    <td>{{record.amount}}</td>
                    <td>
                        <div class="text-warning font-weight-bold" v-if="record.status=='PENDING'">{{record.status}}</div>
                        <div class="text-success font-weight-bold" v-if="record.status=='COMPLETED'">{{record.status}}</div>
                        <div class="text-success font-weight-bold" v-if="record.status=='APPROVED'">{{record.status}}</div>
                        <div class="text-danger font-weight-bold" v-if="record.status=='REJECTED'">{{record.status}}</div>
                    </td>
                    <td>{{momentFormat16(record.created_at)}}</td>
                    <td>
                        <mdb-btn color="primary" @click="showDetail(record)" size="sm"><mdb-icon far icon="eye" /></mdb-btn>
                    </td>
                </tr>
                </mdb-tbl-body>
            </mdb-tbl>
            <!-- <PartnerTransactionDetail :visible="partner_transaction_detail_modal" :close="closeModal" :transaction="focus_record" /> -->
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbBtn, mdbDatePicker, mdbIcon, mdbSpinner, mdbTbl, mdbTblHead, mdbTblBody} from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
// import {APP_STORAGE_URL} from '../js/config';
import {momentFormat16} from '../js/helpers';
// import PartnerTransactionDetail from '../components/PartnerTransactionDetail';
// import Multiselect from 'vue-multiselect'

export default {
  name: 'wallet_transactions',
  components: {
    mdbContainer, mdbRow, mdbCol, mdbBtn, mdbDatePicker,
    AppNavigation, mdbIcon, mdbSpinner, mdbTbl, mdbTblHead, mdbTblBody,
    // Multiselect
  },
  data: ()=>({
      date_from: "",
      date_to: "",
      loading:false,
      records: [],
      partner_transaction_detail_modal: false,
      focus_record: null,
      wallet_id: 0,
      service_type_select: "",
  }),
  methods: {
    closeModal(){
        this.partner_transaction_detail_modal=false;
    },
    getData(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/get_user_wallet_transactions",
            data: {
                date_from: this.date_from,
                date_to: this.date_to,
                wallet_id: this.wallet_id,
                service_type: this.service_type_select
            },
            complete: ()=>this.loading=false,
            success: result=>{
                let records = [...result.data.transactions];
                this.records=records;
            },
        }, this)
    },
    showDetail(record){
        this.focus_record=record;
        this.partner_transaction_detail_modal=true;
    },
    momentFormat16(date){
        return momentFormat16(date);
    },
    // downloadExcel(){
    //     this.excel_loading=true;
    //     ajax({
    //         url: "admin/excel_wallet_transactions",
    //         data: {
    //             date_from: this.date_from,
    //             date_to: this.date_to,
    //         },
    //         success: ()=>{
    //             this.$notify.success({message: 'Downloaded Document will be available in few minutes time', position:'top right', timeOut: 5000});
    //         },
    //         complete: ()=>this.excel_loading=false,
    //         fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
    //     })
    // },
  },
  mounted: function(){
    let date = new Date();
    let month = date.getMonth()+1;
    this.date_from=date.getFullYear()+'-'+("0" + month).slice(-2)+'-'+("0" + date.getDate()).slice(-2);
    this.date_to=date.getFullYear()+'-'+("0" + month).slice(-2)+'-'+("0" + date.getDate()).slice(-2);
    this.wallet_id=this.$route.params.id;
    this.getData();    
  }
}
</script>

<style>
.link-text:hover{
    color: #4285F4;
    cursor: pointer;
}
</style>
