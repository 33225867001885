<template>
  <div>
    <mdb-modal size="lg" :show="visible" @shown="componentDidMount" @close="hideModal">
      <mdb-modal-header>
        <mdb-modal-title>{{wallet!=null?wallet.wallet_number:"Fetching Data"}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body v-if="wallet!=null">
        <mdb-row>
            <mdb-col md="6">
                <mdb-input class="m-1" disabled outline label="Name" v-model="wallet.user.name" /> 
                <mdb-input class="m-1" disabled outline label="Username" v-model="wallet.user.username" /> 
                <mdb-input class="m-1" disabled outline label="Email" v-model="wallet.user.email" /> 
            </mdb-col>
            <mdb-col md="6">
                <mdb-input class="m-1" disabled outline label="Wallet Number" v-model="wallet.wallet_number" /> 
                <mdb-input class="m-1" disabled outline label="Balance" v-model="wallet.balance" /> 
                <mdb-input class="m-1" disabled outline label="On Hold" v-model="wallet.hold_amount" /> 
            </mdb-col>
        </mdb-row>
        <h3 v-if="type!=0"  class="mt-5">{{type==1?"Top Up Wallet":"Deduct Wallet"}}</h3>
        <mdb-row v-if="type!=0">
            <mdb-col col="12">
                <div class="d-flex flex-wrap flex-center">
                    <mdb-btn @click="amount=50" :color="type==1?'success':'danger'">RM 50</mdb-btn>
                    <mdb-btn @click="amount=100" :color="type==1?'success':'danger'">RM 100</mdb-btn>
                    <mdb-btn @click="amount=150" :color="type==1?'success':'danger'">RM 150</mdb-btn>
                    <mdb-btn @click="amount=200" :color="type==1?'success':'danger'">RM 200</mdb-btn>
                    <mdb-btn @click="amount=250" :color="type==1?'success':'danger'">RM 250</mdb-btn>
                    <mdb-btn @click="amount=300" :color="type==1?'success':'danger'">RM 300</mdb-btn>
                    <mdb-btn @click="amount=350" :color="type==1?'success':'danger'">RM 350</mdb-btn>
                    <mdb-btn @click="amount=400" :color="type==1?'success':'danger'">RM 400</mdb-btn>
                    <mdb-btn @click="amount=450" :color="type==1?'success':'danger'">RM 450</mdb-btn>
                    <mdb-btn @click="amount=500" :color="type==1?'success':'danger'">RM 500</mdb-btn>
                </div>
            </mdb-col>
        </mdb-row>
        <mdb-row v-if="type!=0">
            <mdb-col md="8" offsetMd="2">
                <mdb-row>
                    <mdb-col col="12">
                        <mdb-input class="mt-3 mb-3" outline label="Amount" v-model="amount" />         
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col col="12">
                        <mdb-input class="m-1" outline label="Remark" v-model="remark" />         
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col v-for="(image,index) in attachments" v-bind:key="image" col="3" class="d-flex flex-column align-items-center">
                        <img class="waves-effect img-fluid" :src="renderAttachment(image)" />
                        <mdb-btn @click="deleteAttachment(index)" color="danger" size="sm"><mdb-icon icon="times" /></mdb-btn>
                    </mdb-col>
                    <mdb-col col="3" class="py-2">
                        <mdb-spinner v-if="loading_upload" small />
                        <mdb-btn v-if="!loading_upload" size="sm" @click="showAttachmentUpload">
                            <mdb-icon far icon="plus-square" /> Add Attachments
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-col>
        </mdb-row>
        <input multiple type="file" @change="attachmentUpload" id="document-upload" hidden />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-tooltip v-if="type==0" trigger="hover" :options="{placement: 'top'}">
            <span slot="tip">Top Up Balance</span>
            <a slot="reference">
            <mdb-btn @click="type=1" color="success"><mdb-icon icon="plus" /></mdb-btn>
            </a>
        </mdb-tooltip>
        <mdb-tooltip v-if="type==0" trigger="hover" :options="{placement: 'top'}">
            <span slot="tip">Deduct Balance</span>
            <a slot="reference">
            <mdb-btn @click="type=2" color="danger"><mdb-icon icon="minus" /></mdb-btn>
            </a>
        </mdb-tooltip>
        <mdb-spinner v-if="loading" small color="blue"/>
        <mdb-btn @click="confirmationManageWallet" v-if="!loading && type!=0" color="success">Submit</mdb-btn>
        <mdb-btn @click="type=0" v-if="!loading && type!=0" color="danger">Cancel</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <ConfirmationModal :text="text" title="Are you sure?" :visible="show_confirmation_modal" :close="closeModal" :submit="submitManageWallet" />
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbSpinner, mdbRow, mdbCol, mdbIcon, mdbTooltip, mdbInput } from 'mdbvue';
  import {APP_FILE_URL} from '../js/config';
  import {fileUploadAjax, ajax} from '../js/ajax';
import ConfirmationModal from '../components/ConfirmationModal';
//   import {getColor} from '../js/colors';
//   import {checkPermissions} from '../js/helper';
import {validateRequired, validateNumber} from '../js/validation';

  export default {
    components: {
      mdbModal,
      mdbModalHeader, mdbSpinner,
      mdbModalTitle, mdbRow, mdbCol,
      mdbModalBody, mdbTooltip,
      mdbModalFooter, mdbIcon,
      mdbBtn, mdbInput, ConfirmationModal,
    },
    props: ['visible', 'close', 'wallet', 'callback'],
    data: ()=>({
        loading: false,
        amount: "",
        remark: "",
        attachments:[],
        loading_upload: false,
        type: 0,
        show_confirmation_modal: false,
        text: "",
    }),
    methods: {
        componentDidMount(){
            this.type=0;
            this.loading_upload=false;
            this.amount="";
            this.loading=false;
            this.remark="";
            this.attachments=[];
        },
        hideModal(){
            this.type=0;
            this.loading_upload=false;
            this.amount="";
            this.loading=false;
            this.remark="";
            this.attachments=[];
            this.close();
        },
        closeModal(){
            this.show_confirmation_modal=false;
        },
        getAvatar(avatar){
          return APP_FILE_URL+avatar;
        },
        renderAttachment(image=null){
            return image?APP_FILE_URL+image:require('@/assets/picture.png');
        },
        showAttachmentUpload(){
            document.getElementById("document-upload").click();
        },
        attachmentUpload(event){
            this.loading_upload=true;
            fileUploadAjax({
                url: "admin/upload_wallet_transactions",
                data: {event: event.target.files},
                complete: ()=>this.loading_upload=false,
                success: result=>{
                    this.attachments=this.attachments.concat(result.data);
                },
                isShowAlert: true
            }, this)
        },
        deleteAttachment(index){
            var temp=[...this.attachments];
            temp.splice(index, 1);
            this.attachments=temp;
        },
        confirmationManageWallet(){
            if(!validateNumber(this.amount)){
                this.$notify.error({message: 'Invalid Amount', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.remark)){
                this.$notify.error({message: 'Remark is required', position:'top right', timeOut: 5000});
                return false;
            }
            if(this.attachments.length==0){
                this.$notify.error({message: 'Attachment is required', position:'top right', timeOut: 5000});
                return false;
            }
            this.text=this.type==1?"Top Up RM "+parseFloat(this.amount).toFixed(2)+" to "+this.wallet.user.name+"?":"Deduct RM "+this.amount+" from "+this.wallet.user.name+"?";
            this.show_confirmation_modal=true;
       },
        submitManageWallet(){
            this.loading=true;
            ajax({
                url: "admin/submit_manage_wallet",
                data: {
                    wallet_id: this.wallet.id, 
                    wallet: this.wallet, 
                    amount: this.amount,
                    remark: this.remark, 
                    attachments: this.attachments,
                    type: this.type
                },
                complete: ()=>this.loading=false,
                success: ()=>{
                    // this.wallet=result.data;
                    this.callback();
                    this.$notify.success({message: "Wallet Updated", position: 'top right', timeOut: 5000})
                    this.hideModal();
                },
                fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
            })
        },
    }
  };
</script>

<style scoped>
.label-partner{
    font-size: 0.8rem;
    color: #9e9e9e;
}
.avatar-image{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 150px;
    width: 150px;
    border-radius: 150px;
}
</style>