import moment from 'moment';

export const momentFormat16=(date)=>{
    if(date){
        return moment(date, "YYYY-MM-DD h:mm:ss").utcOffset('+16:00').format('DD/MM/YYYY h:mm a');
    }
    return "";
}

export const momentFormat=(date)=>{
    if(date){
        return moment(date, "YYYY-MM-DD h:mm:ss").format('DD/MM/YYYY h:mm a');
    }
    return "";
}

export const momentFormatDate=(date)=>{
    if(date){
        return moment(date, "YYYY-MM-DD h:mm:ss").format('DD/MM/YYYY');
    }
    return "";
}

export const momentFormatDate16=(date)=>{
    if(date){
        return moment(date, "YYYY-MM-DD h:mm:ss").utcOffset('+16:00').format('DD/MM/YYYY');
    }
    return "";
}

export const checkPermissionsBinary=(position, permission)=>{
    try{
        // console.log(position, permission.charAt(position-1));
        if(position >= permission){
            return false;
        }
        if(permission.charAt(position-1)==1){
            return true;
        }
        return false;
    }catch(e){
        console.log(e);
        console.log(position, permission);
    }
}

export const getRandomColor =()=>{
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const amount =(amount)=>{
    try{
        if(amount){
            return parseFloat(amount).toFixed(2);
        }   
        return "0.00"; 
    }catch(e){
        return 'Undefined';
    }
}

export const time15minsInterval=()=>{
    var x = 15; //minutes interval
    var times = []; // time array
    var tt = 0; // start time

    //loop to increment the time and push results in array
    for (var i=0;tt<24*60; i++) {
        var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
        var mm = (tt%60); // getting minutes of the hour in 0-55 format
        times[i] = ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
    }

    return times;
}