<template>
  <mdb-side-nav-2 v-model="show" :data="navigation" :over="over" @setOffset="togglerOffset = $event"
    v-mdb-resize:start="checkWidth">
    <div slot="header">
      <div class="d-flex flex-center p-2">
        <img :src="require('@/assets/logo.png')" class="img-fluid" style="height: 150px; width: 150px" />
      </div>
    </div>
    <mdb-navbar slot="nav" position="top" color="indigo" dark :toggler="false">
      <mdb-navbar-nav left>
        <mdb-icon :style="{
          paddingLeft: togglerOffset + 'px',
          transition: 'padding-left .3s linear'
        }" icon="bars" color="dark-grey-text" size="lg" @click.native="show = !show" />
      </mdb-navbar-nav>
      <mdb-navbar-nav class="nav-flex-icons" right>
        <mdb-dropdown tag="li" class="nav-item">
          <mdb-dropdown-toggle tag="a" navLink color="indigo" slot="toggle" waves-fixed>
            <mdb-icon icon="user" /> {{ this.$store.state.user.username }}
          </mdb-dropdown-toggle>
          <mdb-dropdown-menu>
            <mdb-dropdown-item @click="changePassword">Change Password</mdb-dropdown-item>
            <mdb-dropdown-item @click="logout">Logout</mdb-dropdown-item>
          </mdb-dropdown-menu>
        </mdb-dropdown>
      </mdb-navbar-nav>
    </mdb-navbar>
    <div slot="main" style="height: 85vh; margin-top: 5rem;">
      <slot></slot>
    </div>
  </mdb-side-nav-2>
</template>

<script>
//   import {checkPermissionsBinary} from '../js/helper';
import moment from 'moment';
import {
  mdbNavbar,
  mdbNavbarNav,
  mdbSideNav2,
  mdbIcon, mdbDropdownItem,
  mdbDropdown, mdbDropdownMenu, mdbDropdownToggle,
  waves,
  mdbResize
} from "mdbvue";

export default {
  name: "DoubleNavigationPagev1",
  components: {
    mdbNavbar,
    mdbDropdownItem,
    mdbNavbarNav,
    mdbSideNav2,
    mdbIcon, mdbDropdown, mdbDropdownMenu, mdbDropdownToggle
  },
  data() {
    return {
      show: true,
      over: false,
      togglerOffset: 0,
      navigation: [],
    };
  },
  methods: {
    convertTime(date) {
      return moment(date).format('DD/MM/YYYY h:mm a');
    },
    checkWidth() {
      this.over = window.innerWidth < 900;
    },
    logout() {
      localStorage.clear();
      window.location.href = "/";
    },
    changePassword() {
      this.$router.push({ name: 'change_password' })
    },
  },
  mixins: [waves],
  directives: {
    mdbResize
  },
  destroyed: function () {
    // this.unsubscribe();
  },
  mounted: function () {
    // let permissions=this.$store.state.permissions;
    this.navigation.push({
      name: "Dashboard",
      icon: "chart-bar",
      to: "/landing"
    })
    this.navigation.push({
      name: "Orders",
      icon: "file-invoice",
      children: [
        {
          name: "All Orders",
          to: "/active_orders"
        },
      ]
    })
    this.navigation.push({
      name: "Admins Management",
      icon: "user-tie",
      children: [
        {
          name: "Admins",
          to: "/admins"
        },
        {
          name: "Permissions",
          to: "/permissions"
        },
        {
          name: "Roles",
          to: "/roles"
        }
      ]
    })
    this.navigation.push({
      name: "Users Management",
      icon: "users",
      children: [
        {
          name: "Users",
          to: "/users"
        },
      ]
    })
    this.navigation.push({
      name: "Wallet Management",
      icon: "wallet",
      children: [{
        name: "User Wallets",
        to: "/wallet_management"
      }, {
        name: "Wallet Transactions",
        to: "/wallet_transactions"
      },
      ]
    })
    this.navigation.push({
      name: "eCommerce",
      icon: "tools",
      children: [{
        name: "Category",
        to: "/product_category"
      }, {
        name: "Add Product",
        to: "/submit_product"
      }, {
        name: "Product List",
        to: "/product_list"
      },
      ]
    })
    this.navigation.push({
      name: "Home Care",
      icon: "home",
      children: [
        {
          name: "Home Care Attributes",
          to: "/home_care_attributes"
        }, {
          name: "Add Home Care",
          to: "/submit_home_care"
        }, {
          name: "Home Care List",
          to: "/home_care_list"
        },
      ]
    })
    this.navigation.push({
      name: "Booking Stores",
      icon: "store",
      children: [
        {
          name: "Add New Store",
          to: "/submit_booking_store"
        }, {
          name: "Store List",
          to: "/booking_store_list"
        }
      ]
    })
    this.navigation.push({
      name: "Reports",
      icon: "chart-line",
      children: [{
        name: "Daily Service Report",
        to: "/daily_service_report"
      }, {
        name: "Monthly Settlement",
        to: "/monthly_settlement"
      },
      ]
    })
    this.navigation.push({
      name: "App Configurations",
      icon: "shopping-cart",
      children: [{
        name: "App Landing Configuration",
        to: "/app_home_config"
      }, {
        name: "Zone Coverage",
        to: "/zone_coverage"
      }, {
        name: "Service Templates",
        to: "/service_templates"
      }, {
        name: "App Version Control",
        to: "/app_version_control"
      },
      ]
    })
  }
};
</script>

<style scoped>
.navbar i {
  cursor: pointer;
  color: white;
}
</style>