<template>
    <div>
        <mdb-modal scrollable size="lg" @shown="componentDidMount" :show="visible" @close="hideModal">
            <mdb-modal-header>
                <mdb-modal-title>Order Detail {{ this.order ? this.order.order_number : "" }}</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <mdb-container>
                    <mdb-row v-if="loading">
                        <mdb-col col="12" class="d-flex flex-center">
                            <mdb-spinner />
                        </mdb-col>
                    </mdb-row>
                    <mdb-row class="mb-3" v-if="!loading && this.order && this.order.comment_user">
                        <mdb-col col="12">
                            <div class="pb-remark">{{ this.order.comment_user }}</div>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row v-if="!loading" class="mb-3">
                        <mdb-col md="6" v-if="this.order.pickup">
                            <mdb-card>
                                <mdb-card-body>
                                    <div style="border-bottom: 1px solid grey">
                                        Pick Up
                                    </div>
                                    <div class="text-primary">{{ this.order ? this.order.pickup.name : "" }}</div>
                                    <div class="small">{{ this.order ? this.order.pickup.address : "" }}</div>
                                    <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
                                        <span slot="tip">Google Map</span>
                                        <a slot="reference" target="_blank"
                                            :href="`https://www.google.com/maps?q=${this.order.pickup.lat},${this.order.pickup.lng}`">
                                            <i class="far fa-map"></i> Open Map
                                        </a>
                                    </mdb-tooltip>
                                </mdb-card-body>
                            </mdb-card>
                        </mdb-col>
                        <mdb-col md="6" v-if="this.order.deliver">
                            <mdb-card>
                                <mdb-card-body>
                                    <div style="border-bottom: 1px solid grey">
                                        Deliver
                                    </div>
                                    <div class="text-success">{{ this.order ? this.order.deliver.name : "" }}</div>
                                    <div class="small">{{ this.order ? this.order.deliver.address : "" }}</div>
                                    <div class="small" v-if="this.order.deliver.remarks">Unit:
                                        {{ this.order.deliver.remarks }}</div>
                                    <div class="small" v-if="this.order.note_customer">Note:
                                        {{ this.order.note_customer }}</div>
                                    <div class="small">{{ this.order ? this.order.customer_user.username : "" }}</div>
                                    <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
                                        <span slot="tip">Google Map</span>
                                        <a slot="reference" target="_blank"
                                            :href="`https://www.google.com/maps?q=${this.order.deliver.lat},${this.order.deliver.lng}`">
                                            <i class="far fa-map"></i> Open Map
                                        </a>
                                    </mdb-tooltip>
                                </mdb-card-body>
                            </mdb-card>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row v-if="!loading" class="mb-3">
                        <mdb-col md="12">
                            <mdb-card>
                                <mdb-card-body>
                                    <h4 class="h4-responsive" :style="{ color: getColor('APP_1') }">Order Information
                                    </h4>
                                    <mdb-container fluid v-if="order.items.length > 0">
                                        <mdb-row class="py-1" v-for="item in order.items"
                                            style="border-bottom: 1px solid grey" :key="item.id">
                                            <mdb-col col="1">
                                                <img class="img-fluid" :src="getProductAvatar(item.product.avatar)" />
                                            </mdb-col>
                                            <mdb-col col="6" class="text">
                                                <!-- <div :class="{ 'text-danger': item.is_available == 0 }">
                                                    {{ item.product.allcategory.name }}
                                                </div> -->
                                                <div class="font-weight-bold d-flex flex-column">
                                                    <div class="d-flex flex-row align-items-center">
                                                        {{ item.name }}
                                                    </div>
                                                </div>
                                                <div style="font-size: 0.75rem">
                                                    {{ item.product.description }}
                                                </div>
                                                <div v-if="item.start_at && item.end_at"
                                                    class="font-weight-bold text-primary">
                                                    Rental {{ momentFormatDate(item.start_at) }} -
                                                    {{ momentFormatDate(item.end_at) }}
                                                </div>
                                                <div v-if="item.remarks">Remarks: {{ item.remarks }}</div>
                                            </mdb-col>
                                            <mdb-col col="5" class="text-right">
                                                <div class="text">{{ item.quantity }} items</div>
                                                <div class="text">RM {{ item.price }}</div>
                                            </mdb-col>
                                        </mdb-row>
                                        <mdb-row class="py-1" style="border-bottom: 1px solid grey">
                                            <mdb-col col="6" class="text font-weight-bold">Total Items</mdb-col>
                                            <mdb-col col="6" class="text-right font-weight-bold">
                                                {{ this.item_quantity }}
                                            </mdb-col>
                                        </mdb-row>
                                    </mdb-container>
                                    <mdb-container fluid v-if="order.home_care_node">
                                        <mdb-row class="py-1" style="border-bottom: 1px solid grey">
                                            <mdb-col col="1">
                                                <img class="img-fluid"
                                                    :src="getProductAvatar(order.home_care_node.home_care.avatar)" />
                                            </mdb-col>
                                            <mdb-col col="6" class="text">
                                                <div class="font-weight-bold d-flex flex-column">
                                                    <div class="d-flex flex-row align-items-center">
                                                        {{ order.home_care_node.name }}
                                                    </div>
                                                </div>
                                                <div class="font-weight-bold text-primary">
                                                    {{ momentFormat(order.scheduled_at) }} -
                                                    {{ momentFormat(order.end_at) }}
                                                </div>
                                                <div v-if="order.comment_user">Remarks: {{ order.comment_user }}</div>
                                            </mdb-col>
                                            <mdb-col col="5" class="text-right">
                                                <div class="text">{{ order.home_care_node.quantity }} hours</div>
                                                <div class="text">RM {{ order.home_care_node.price }}</div>
                                            </mdb-col>
                                        </mdb-row>
                                        <mdb-row class="py-1" style="border-bottom: 1px solid grey">
                                            <mdb-col col="6" class="text font-weight-bold">Total Hours</mdb-col>
                                            <mdb-col col="6" class="text-right font-weight-bold">
                                                {{ this.order.home_care_node.quantity }}
                                            </mdb-col>
                                        </mdb-row>
                                    </mdb-container>
                                    <mdb-container fluid v-if="attachments.length > 0">
                                        <mdb-row class="py-1" style="border-bottom: 1px solid grey">
                                            <mdb-col col="6" v-for="(attach, index) in attachments" :key="attach.id">
                                                <img @click="showLightBox(index)" class="waves-effect img-fluid mb-1"
                                                    :src="getProductAvatar(attach.path)" />
                                                <mdb-input class="m-0" outline type="text" placeholder="Description"
                                                    v-model="attach.description" />
                                                <mdb-input class="m-0" outline type="text" placeholder="Price"
                                                    v-model="attach.price" />
                                            </mdb-col>
                                        </mdb-row>
                                        <mdb-row class="py-1" style="border-bottom: 1px solid grey">
                                            <mdb-col col="6" class="d-flex align-items-end">Total: <strong
                                                    class="ml-1">RM
                                                    {{ parseFloat(total_attachment_amount).toFixed(2)
                                                    }}</strong></mdb-col>
                                            <mdb-col col="6" class="d-flex align-items-end justify-content-end">
                                                <mdb-btn v-if="order.grandtotal == 0" size="sm"
                                                    @click="submitMedicationPrice">
                                                    Submit Medication
                                                </mdb-btn>
                                            </mdb-col>
                                        </mdb-row>
                                    </mdb-container>
                                    <mdb-container fluid v-if="order.booking_store_node">
                                        <mdb-row class="py-1" style="border-bottom: 1px solid grey">
                                            <mdb-col col="1">
                                                <img class="img-fluid"
                                                    :src="getProductAvatar(order.booking_store_node.avatar)" />
                                            </mdb-col>
                                            <mdb-col col="6" class="text">
                                                <div class="d-flex flex-column">
                                                    <div class="d-flex flex-row align-items-center">
                                                        {{ order.booking_store_node.name }}
                                                    </div>
                                                    <div class="d-flex flex-row align-items-center">
                                                        {{ order.booking_store_node.description }}
                                                    </div>
                                                    <div class="d-flex flex-row align-items-center text-muted">
                                                        {{ order.booking_store_node.address }}
                                                    </div>
                                                </div>
                                            </mdb-col>
                                        </mdb-row>
                                    </mdb-container>
                                </mdb-card-body>
                            </mdb-card>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row v-if="!loading" class="mb-3">
                        <mdb-col md="12">
                            <mdb-card>
                                <mdb-card-body>
                                    <h4 class="h4-responsive" :style="{ color: getColor('APP_1') }">Payment Information
                                    </h4>
                                    <mdb-container fluid>
                                        <mdb-row class="py-1" v-if="item_price != 0">
                                            <mdb-col col="6" class="text">Subtotal</mdb-col>
                                            <mdb-col col="6" class="text-right">
                                                RM {{ parsePrice(this.item_price) }}
                                            </mdb-col>
                                        </mdb-row>
                                        <mdb-row class="py-1" v-if="this.order.extra_charges">
                                            <mdb-col col="6" class="text">Extra Charges</mdb-col>
                                            <mdb-col col="6" class="text-right">
                                                RM {{ parsePrice(this.order.extra_charges) }}
                                            </mdb-col>
                                        </mdb-row>
                                        <mdb-row class="py-1" v-if="this.order.deliver">
                                            <mdb-col col="6" class="text">Delivery Fees</mdb-col>
                                            <mdb-col col="6" class="text-right">
                                                RM {{ parsePrice(this.order.deliver.price) }}
                                            </mdb-col>
                                        </mdb-row>
                                        <mdb-row class="py-1" v-if="this.order.promo_code_paid != '0.00'">
                                            <mdb-col col="6" class="text">Promo Discount
                                                ({{ this.order.promo_code_config.promo_code.code }})</mdb-col>
                                            <mdb-col col="6" class="text-right">
                                                - RM {{ parsePrice(this.order.promo_code_paid) }}
                                            </mdb-col>
                                        </mdb-row>
                                        <mdb-row class="py-1"
                                            style="border-top: 1px solid #2E2E2E; border-bottom: 1px solid #2E2E2E">
                                            <mdb-col col="6" class="text font-weight-bold">Total
                                                <span v-if="this.order.payment_method">({{ this.order.payment_method
                                                }})</span></mdb-col>
                                            <mdb-col col="6" class="text-right font-weight-bold">
                                                RM {{ parsePrice(this.order.grandtotal) }}
                                            </mdb-col>
                                        </mdb-row>
                                    </mdb-container>
                                </mdb-card-body>
                            </mdb-card>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row v-if="!loading && order.service_order_event.length != 0" class="mb-3">
                        <mdb-col md="12">
                            <mdb-card>
                                <mdb-card-body>
                                    <h4 class="h4-responsive" :style="{ color: getColor('APP_1') }">Event Information
                                    </h4>
                                    <mdb-container fluid>
                                        <mdb-row v-for="event in order.service_order_event" v-bind:key="event.id">
                                            <mdb-col col="4">
                                                {{ event.code_type }}
                                            </mdb-col>
                                            <mdb-col col="4">
                                                {{ event.description }}
                                            </mdb-col>
                                            <mdb-col col="4">
                                                {{ momentFormat16(event.created_at) }}
                                            </mdb-col>
                                        </mdb-row>
                                    </mdb-container>
                                </mdb-card-body>
                            </mdb-card>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row v-if="!loading" class="mb-3">
                        <mdb-col md="12">
                            <mdb-card>
                                <mdb-card-body>
                                    <h4 class="h4-responsive" :style="{ color: getColor('APP_1') }">Admin Remarks</h4>
                                    <div v-html="order.comment_admin" style="white-space: pre-wrap;"><br /></div>
                                    <mdb-input outline type="textarea" v-model="admin_comment" :rows="2" />
                                    <mdb-btn color="success" size="sm" @click="updateRemarks">Update Remarks</mdb-btn>
                                </mdb-card-body>
                            </mdb-card>
                        </mdb-col>
                    </mdb-row>
                </mdb-container>
            </mdb-modal-body>
            <mdb-modal-footer>
                <!-- <mdb-spinner color="primary" /> -->
                <mdb-btn color="info" size="sm" v-if="!loading && checkAcceptOrder()"
                    @click.native="acceptOrder()">Accept Order</mdb-btn>
                <mdb-btn color="primary" size="sm" v-if="!loading && checkExecutingOrder()"
                    @click.native="executingOrder()">Executing Order</mdb-btn>
                <mdb-btn color="success" size="sm" v-if="!loading && checkCompleteOrder()"
                    @click.native="completeOrder()">Complete Order</mdb-btn>
                <mdb-btn color="danger" size="sm" v-if="!loading && checkCancelOrder()"
                    @click.native="showConfirmationModal()">Cancel Order</mdb-btn>
            </mdb-modal-footer>
            <mdb-lightbox :visible="lightbox_modal" :imgs="lightbox_imgs" :index="lightbox_index"
                @hide="lightbox_modal = false"></mdb-lightbox>
            <ConfirmationModal :title="'Are you sure you want to cancel order?'" :visible="show_confirmation_modal"
                :close="closeModal" :submit="cancelOrder" />
        </mdb-modal>
    </div>
</template>

<script>
import {
    mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbContainer, mdbRow, mdbCol, mdbSpinner,
    mdbCard, mdbCardBody, mdbInput, mdbTooltip, mdbLightbox
} from 'mdbvue';
import { ajax } from '../js/ajax';
import { momentFormat16, checkPermissionsBinary, momentFormatDate, momentFormat } from '../js/helpers';
import { getColor } from '../js/colors';
import ConfirmationModal from '../components/ConfirmationModal';
import { APP_FILE_URL } from '../js/config'
import { validateNumber } from '../js/validation';

export default {
    components: {
        mdbModal, mdbInput, mdbTooltip,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter, mdbLightbox,
        mdbBtn, mdbContainer, mdbRow, mdbCol,
        mdbSpinner, mdbCard, mdbCardBody, ConfirmationModal
    },
    props: ['visible', 'close', "id", 'callback', 'promo_codes'],
    data: () => ({
        loading: true,
        order: null,
        item_quantity: 0,
        item_price: 0,
        map: null,
        admin_comment: "",
        show_confirmation_modal: false,
        textarea_pickup: "",
        textarea_deliver: "",
        selected_promo_code: "",
        promo_code_loading: false,
        order_merchants: [],
        dispute_modal: false,
        show_remove_item_modal: false,
        focus_item: null,
        remove_item_text: "",
        loading_upload: false,
        attachments: [],
        total_attachment_amount: "0.00",
        lightbox_modal: false,
        lightbox_imgs: [],
        lightbox_index: 0,
    }),
    methods: {
        componentDidMount() {
            this.order = null;
            this.loading = true;
            this.getData();
        },
        momentFormatDate(date) {
            return momentFormatDate(date);
        },
        momentFormat(date) {
            return momentFormat(date);
        },
        getData() {
            this.loading = true;
            this.selected_promo_code = "";
            ajax({
                url: "admin/get_order_detail",
                data: {
                    id: this.id
                },
                complete: () => this.loading = false,
                success: result => {
                    if (!this.visible) {
                        this.order = null;
                        this.loading = true;
                        return false;
                    } else {
                        var quantity = 0;
                        var price = 0;
                        if (result.data.items.length != 0) {
                            result.data.items.forEach(product => {
                                quantity += parseFloat(product.quantity);
                                price += parseFloat(product.price);
                            });
                        }
                        this.attachments = [...result.data.service_order_attachments];
                        this.order = result.data;
                        this.item_price = price;
                        this.item_quantity = quantity
                    }

                },
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        },
        acceptOrder() {
            ajax({
                url: "admin/accept_order",
                data: {
                    id: this.order.id
                },
                complete: () => this.loading = false,
                success: result => {
                    this.order = result.data;
                    this.callback ? this.callback(result.data) : null;
                    this.$notify.success({ message: "Order Accepted", position: 'top right', timeOut: 5000 })
                },
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        },
        executingOrder() {
            ajax({
                url: "admin/execute_order",
                data: {
                    id: this.order.id
                },
                complete: () => this.loading = false,
                success: result => {
                    this.order = result.data;
                    this.callback ? this.callback(result.data) : null;
                    this.$notify.success({ message: "Order Executing", position: 'top right', timeOut: 5000 })
                },
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        },
        completeOrder() {
            this.loading = true;
            ajax({
                url: "admin/complete_order",
                data: {
                    id: this.order.id
                },
                complete: () => this.loading = false,
                success: result => {
                    this.order = result.data;
                    this.callback ? this.callback(result.data) : null;
                    this.$notify.success({ message: "Order Completed", position: 'top right', timeOut: 5000 })
                },
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        },
        cancelOrder() {
            this.loading = true;
            ajax({
                url: "admin/cancel_order",
                data: {
                    id: this.order.id
                },
                complete: () => this.loading = false,
                success: result => {
                    this.order = result.data;
                    this.callback ? this.callback(result.data) : null;
                    this.$notify.success({ message: "Order Cancelled", position: 'top right', timeOut: 5000 })
                },
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        },
        submitMedicationPrice() {
            var validation = true;
            this.attachments.forEach(element => {
                if (!validateNumber(element.price)) {
                    validation = false;
                    this.$notify.error({ message: "Invalid Attachment Price", position: 'top right', timeOut: 5000 })
                }
            });
            if (!validation) {
                return false;
            }
            this.loading = true;
            ajax({
                url: "admin/submit_attachment_detail",
                data: {
                    attachments: this.attachments,
                    id: this.order.id
                },
                complete: () => this.loading = false,
                success: result => {
                    console.log(result);
                },
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        },
        hideModal() {
            this.order = null;
            this.lightbox_modal = false;
            this.dispute_modal = false;
            this.lightbox_imgs = [];
            this.lightbox_index = 0;
            this.loading = true;
            this.closeModal();
            this.close();
        },
        parsePrice(price) {
            return parseFloat(price).toFixed(2);
        },
        getColor(text) {
            return getColor(text);
        },
        momentFormat16(date) {
            return momentFormat16(date);
        },
        updateRemarks() {
            ajax({
                url: "admin/submit_remarks",
                data: {
                    id: this.order.id,
                    admin_comment: this.admin_comment
                },
                success: () => {
                    if (this.order.comment_admin) {
                        this.order.comment_admin = this.order.comment_admin + '<br/>' + this.admin_comment;
                    } else {
                        this.order.comment_admin = this.admin_comment;
                    }
                    this.admin_comment = "";
                    this.$notify.success({ message: "Admin Remarks Updated", position: 'top right', timeOut: 5000 });
                },
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        },
        checkAcceptOrder() {
            if (this.order && this.order.status == 'NEW' && this.order.current_step == 'NEW') {
                return true;
            }
            return false;
        },
        checkExecutingOrder() {
            if (this.order && this.order.status == 'NEW' && this.order.current_step == 'ACCEPTED_PREPARE') {
                return true;
            }
            return false;
        },
        checkCompleteOrder() {
            if (this.order && this.order.status == 'NEW' && this.order.current_step == 'EXECUTING') {
                return true;
            }
            return false;
        },
        checkCancelOrder() {
            if (this.order && this.order.status == 'NEW') {
                return true;
            }
            return false;
        },
        permissionCheck(permission) {
            return checkPermissionsBinary(permission, this.$store.state.permissions);
        },
        closeModal() {
            this.show_confirmation_modal = false;
            this.dispute_modal = false;
            this.show_remove_item_modal = false;
        },
        showConfirmationModal() {
            this.show_confirmation_modal = true;
        },
        getProductAvatar(avatar) {
            return APP_FILE_URL + avatar;
        },
        showLightBox(index) {
            var temp_images = [];
            this.order.service_order_attachments.forEach(element => {
                temp_images.push(APP_FILE_URL + element.path);
            });
            this.lightbox_modal = true,
                this.lightbox_imgs = temp_images,
                this.lightbox_index = index;
        }
    },
    watch: {
        attachments: {
            handler(val) {
                let total_attachment_amount = 0;
                val.forEach(element => {
                    if (validateNumber(element.price)) {
                        total_attachment_amount = total_attachment_amount + parseFloat(element.price);
                    }
                });
                this.total_attachment_amount = total_attachment_amount;
            },
            deep: true
        }
    }
};
</script>

<style scoped>
#map {
    height: 400px;
    width: 100%;
}

.copy-clipboard {
    position: absolute;
    left: -999999999px;
    z-index: -100;
}

.pb-remark {
    background-color: #E91E63;
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 20px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.mart-remark {
    background-color: #4caf50;
    color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.top-right-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
}
</style>