<template>
    <Dialog :visible="visible" @close="closeModal" :title="title" :loading="loading" submitLabel="Submit"
        @onSubmitModal="onSubmitZone" @componentDidMount="componentDidMount">
        <validation-observer slim ref="form">
            <mdb-container>
                <mdb-row>
                    <mdb-col col="12">
                        <validation-provider name="Name" rules="required" v-slot="{ errors, touched }" slim>
                            <mdb-input type="text" v-model="form.name" label="Name" :invalidFeedback="errors[0]"
                                :isValid="!!!errors[0]" :validation="touched" />
                        </validation-provider>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col col="12">
                        <validation-provider name="Address" rules="required" v-slot="{ errors, touched }" slim>
                            <mdb-input type="text" v-model="form.address" label="Address" :invalidFeedback="errors[0]"
                                :isValid="!!!errors[0]" :validation="touched" />
                        </validation-provider>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col col="6">
                        <validation-provider name="Lat" rules="required" v-slot="{ errors, touched }" slim>
                            <mdb-input type="text" v-model="form.lat" label="Lat" :invalidFeedback="errors[0]"
                                :isValid="!!!errors[0]" :validation="touched" />
                        </validation-provider>
                    </mdb-col>
                    <mdb-col col="6">
                        <validation-provider name="Lng" rules="required" v-slot="{ errors, touched }" slim>
                            <mdb-input type="text" v-model="form.lng" label="Lng" :invalidFeedback="errors[0]"
                                :isValid="!!!errors[0]" :validation="touched" />
                        </validation-provider>
                    </mdb-col>
                </mdb-row>
            </mdb-container>
        </validation-observer>
    </Dialog>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbInput } from 'mdbvue';
import Dialog from './Dialog.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
    components: {
        Dialog, ValidationProvider, ValidationObserver,
        mdbContainer, mdbRow, mdbCol, mdbInput
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        focus_location_template: {
            type: Object,
            default: null
        }
    },
    computed: {
        title() {
            return this.zone == null ? "Add New Location Template" : "Edit Location Template"
        }
    },
    data: () => ({
        loading: false,
        form: {
            service_id: 1,
            name: "",
            address: "",
            lat: '',
            lng: ''
        },
    }),
    methods: {
        closeModal() {
            this.form = {
                service_id: 1,
                name: "",
                address: "",
                lat: '',
                lng: ''
            }
            this.$emit('close')
        },
        componentDidMount() {
            if (this.focus_location_template) {
                this.form = { ...this.focus_location_template }
            }
        },
        async onSubmitZone() {
            const success = await this.$refs.form.validate()
            if (!success) {
                this.$notify.error({ message: "Please Fill in all Required Fields", position: 'top right', timeOut: 5000 })
                return false
            }
            this.loading = true;
            this.$ajax({
                url: "admin/upsert_location_template",
                method: "PUT",
                data: this.form,
                success: () => {
                    this.$notify.success({ message: "Location Template Added", position: 'top right', timeOut: 5000 })
                    this.closeModal()
                    this.$emit('success:callback')
                },
                complete: () => this.loading = false,
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        },
    }
};
</script>