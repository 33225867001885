<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Zone Coverage <mdb-btn @click="toggleZoneModal" size="sm" color="primary">New Zone
                </mdb-btn>
            </h1>

            <hr>
            <div id="map"></div>
            <mdb-tbl responsive>
                <mdb-tbl-head>
                    <tr>
                        <th width="5%">#</th>
                        <th>Name</th>
                        <th>Daily Start Time / End Time</th>
                        <th>Zone Charges</th>
                        <th>Search KM</th>
                    </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                    <tr v-for="(zone, index) in zones" :key="zone.id">
                        <td>{{ index + 1 }}</td>
                        <td :style="{ 'color': zone.color }">
                            {{ zone.name }}
                        </td>
                        <td>
                            <div>
                                <mdb-icon far icon="clock" /> {{ zone.operating_start }}
                            </div>
                            <div>
                                <mdb-icon far icon="clock" /> {{ zone.operating_end }}
                            </div>
                        </td>
                        <td>
                            <div>
                                <mdb-icon icon="dollar-sign" /> Internal: RM {{ amount(zone.current_zone_service_fee) }}
                            </div>
                            <div>
                                <mdb-icon icon="dollar-sign" /> Cross: RM {{ amount(zone.cross_zone_service_fee) }}
                            </div>
                        </td>
                        <td>
                            <div>
                                <mdb-icon icon="search" /> {{ zone.search_km }}km
                            </div>
                        </td>
                        <td>
                            <mdb-btn color="primary" @click="view(zone)" size="sm">
                                <mdb-icon far icon="eye" />
                            </mdb-btn>
                            <mdb-btn color="success" @click="updateZone(zone)" size="sm">
                                <mdb-icon far icon="edit" />
                            </mdb-btn>
                            <mdb-btn color="danger" @click="confirmDeleteZone(zone)" size="sm">
                                <mdb-icon far icon="trash-alt" />
                            </mdb-btn>
                        </td>
                    </tr>
                </mdb-tbl-body>
            </mdb-tbl>
            <ConfirmationModal :visible="confirmation_modal" :close="closeModalComponent" title="Confirm Delete Zone?"
                :submit="deleteZone" />
            <ZoneCoverageModal :zone="focus_zone" :zones="zones" :visible="zone_coverage_modal"
                :close="closeModalComponent" />
        </mdb-container>
        <ZoneModal :visible="is_zone_modal" @close="closeModalComponent" @success:callback="getData"
            :zone="focus_zone" />
    </AppNavigation>
</template>

<script>
import { mdbContainer, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn, mdbIcon, } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import { ajax } from '../js/ajax';
import ConfirmationModal from '../components/ConfirmationModal';
import ZoneCoverageModal from '../components/ZoneCoverageModal';
import ZoneModal from '../components/ZoneModal.vue'
import { amount } from '../js/helpers'

export default {
    name: 'zone_coverage',
    components: {
        mdbContainer, mdbTbl, mdbTblHead, mdbTblBody,
        AppNavigation, mdbBtn, ConfirmationModal, mdbIcon,
        ZoneCoverageModal,
        ZoneModal
    },
    data: () => ({
        zones: [],
        new_zone: {
            name: "",
            operating_start: "",
            operating_end: "",
            cross_zone_service_fee: "",
            current_zone_service_fee: "",
            search_km: ""
        },
        loading: false,
        confirmation_modal: false,
        focus_id: 0,
        zone_coverage_modal: false,
        focus_zone: null,
        is_zone_modal: false
    }),
    methods: {
        closeModalComponent() {
            this.confirmation_modal = false
            this.zone_coverage_modal = false
            this.is_zone_modal = false
            this.focus_zone = null
        },
        confirmDeleteZone(zone) {
            this.focus_id = zone.id;
            this.confirmation_modal = true;
        },
        toggleZoneModal() {
            this.is_zone_modal = !this.is_zone_modal
        },
        amount(amt) {
            return amount(amt)
        },
        deleteZone() {
            this.loading = true;
            ajax({
                url: "admin/zone",
                method: "DELETE",
                data: { id: this.focus_id },
                success: () => {
                    this.$notify.success({ message: "Zone Deleted", position: 'top right', timeOut: 5000 })
                    this.getData();
                },
                complete: () => this.loading = false,
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        },

        updateZone(zone) {
            this.focus_zone = zone
            this.is_zone_modal = true
        },
        checkAddZoneValidity() {
            return false;
            // if(!validateRequired(this.new_zone.name)){
            //     return true;
            // }
            // if(!validateRequired(this.new_zone.operating_start)){
            //     return true;
            // }
            // if(!validateRequired(this.new_zone.operating_end)){
            //     return true;
            // }
            // if(!validatePercentage(this.new_zone.partner_search_area)){
            //     return true;
            // }
            // return false;
        },
        getData() {
            this.$ajax({
                url: "admin/zone",
                method: "GET",
                success: result => {
                    this.zones = result.data;
                    this.renderMap();
                },
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        },
        renderMap() {
            const google = window.google;
            if (!this.map) {
                var mapProp = {
                    center: new google.maps.LatLng(5.365375, 100.301449),
                    zoom: 12,
                };
                this.map = new google.maps.Map(document.getElementById("map"), mapProp);
            }
            this.zones.forEach(zone => {
                zone.zone_coverage.forEach(coverage => {
                    const coverage_geolocation = [
                        { lat: parseFloat(coverage.lat_1), lng: parseFloat(coverage.lng_1) },
                        { lat: parseFloat(coverage.lat_2), lng: parseFloat(coverage.lng_2) },
                        { lat: parseFloat(coverage.lat_3), lng: parseFloat(coverage.lng_3) },
                        { lat: parseFloat(coverage.lat_4), lng: parseFloat(coverage.lng_4) },
                    ];
                    // Construct the polygon.
                    const coverage_map = new google.maps.Polygon({
                        paths: coverage_geolocation,
                        strokeColor: zone.color,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: zone.color,
                        fillOpacity: 0.35,
                    });
                    coverage_map.setMap(this.map);
                    var label_latlng = new google.maps.LatLng(coverage.lat_1, coverage.lng_1);

                    var marker = new google.maps.Marker({
                        position: label_latlng,
                        title: 'CoverageID: ' + coverage.id + ' Zone Name: ' + zone.name
                    });
                    marker.setMap(this.map);
                })
            })
        },
        view(zone) {
            this.focus_zone = zone;
            this.zone_coverage_modal = true;
        }
    },
    mounted: function () {
        this.getData();
    }
}
</script>

<style scoped>
#map {
    height: 70vh;
    width: 100%;
    margin-bottom: 5rem;
}
</style>