<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Landing <mdb-btn size="sm" @click="cleanUp" color="primary"><mdb-icon
                        icon="snowplow" /></mdb-btn></h1>
            <hr>
            <mdb-row>
                <mdb-col col="3">
                    <mdb-card>
                        <mdb-card-body>
                            Today Orders: {{ today_pending_create_order }} / {{ today_create_order }}
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col col="3">
                    <mdb-card>
                        <mdb-card-body>
                            Scheduled Orders: {{ today_pending_scheduled_order }} / {{ today_scheduled_order }}
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col col="3">
                    <mdb-card>
                        <mdb-card-body>
                            Future Orders: {{ future_scheduled_order }}
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col col="3">
                    <mdb-card>
                        <mdb-card-body>
                            Expired Orders: {{ expired_scheduled_order }}
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardBody, mdbIcon, mdbBtn } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import { ajax } from '../js/ajax';
// import {APP_FILE_URL} from '../js/config';

export default {
    name: 'Landing',
    components: {
        mdbContainer, mdbIcon, mdbBtn,
        AppNavigation, mdbRow, mdbCol, mdbCard, mdbCardBody
    },
    data: () => ({
        today_create_order: 0,
        today_scheduled_order: 0,
        future_scheduled_order: 0,
        expired_scheduled_order: 0,
        today_pending_create_order: 0,
        today_pending_scheduled_order: 0,
        loading: false,
    }),
    methods: {
        getData() {
            if (this.loading) {
                return false;
            }
            this.loading = true;
            ajax({
                url: "admin/get_dashboard",
                method: "GET",
                complete: () => this.loading = false,
                success: result => {
                    this.today_create_order = result.data.today_create_order;
                    this.today_scheduled_order = result.data.today_scheduled_order;
                    this.future_scheduled_order = result.data.future_scheduled_order;
                    this.expired_scheduled_order = result.data.expired_scheduled_order;
                    this.today_pending_create_order = result.data.today_pending_create_order;
                    this.today_pending_scheduled_order = result.data.today_pending_scheduled_order;
                },
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        },
        cleanUp() {
            if (this.loading) {
                return false;
            }
            this.loading = true;
            ajax({
                url: "admin/clean_up_orders",
                method: "PUT",
                complete: () => this.loading = false,
                success: () => {
                    this.$notify.success({ message: "Clean Up Successfully", position: 'top right', timeOut: 5000 })
                    this.getData();
                },
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        }
    },
    mounted: function () {
        this.getData();
    }
}
</script>
