var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"visible":_vm.visible,"title":_vm.title,"loading":_vm.loading,"submitLabel":"Submit"},on:{"close":_vm.closeModal,"onSubmitModal":_vm.onSubmitZone,"componentDidMount":_vm.componentDidMount}},[_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('mdb-container',[_c('mdb-row',[_c('mdb-col',{attrs:{"col":"12"}},[_c('validation-provider',{attrs:{"name":"Zone Name","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('mdb-input',{attrs:{"type":"text","label":"Zone Name","invalidFeedback":errors[0],"isValid":!!!errors[0],"validation":touched},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])})],1)],1),_c('mdb-row',[_c('mdb-col',{attrs:{"col":"6"}},[_c('validation-provider',{attrs:{"name":"Operating Start","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('mdb-time-picker',{attrs:{"hoursFormat":24,"type":"text","label":"Operating Start","invalidFeedback":errors[0],"isValid":!!!errors[0],"validation":touched},model:{value:(_vm.form.operating_start),callback:function ($$v) {_vm.$set(_vm.form, "operating_start", $$v)},expression:"form.operating_start"}})]}}])})],1),_c('mdb-col',{attrs:{"col":"6"}},[_c('validation-provider',{attrs:{"name":"Operating End","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('mdb-time-picker',{attrs:{"hoursFormat":24,"type":"text","label":"Operating End","invalidFeedback":errors[0],"isValid":!!!errors[0],"validation":touched},model:{value:(_vm.form.operating_end),callback:function ($$v) {_vm.$set(_vm.form, "operating_end", $$v)},expression:"form.operating_end"}})]}}])})],1)],1),_c('mdb-row',[_c('mdb-col',{attrs:{"col":"6"}},[_c('validation-provider',{attrs:{"name":"Current Fee","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('mdb-input',{attrs:{"type":"text","label":"Current Fee","invalidFeedback":errors[0],"isValid":!!!errors[0],"validation":touched},model:{value:(_vm.form.current_zone_service_fee),callback:function ($$v) {_vm.$set(_vm.form, "current_zone_service_fee", $$v)},expression:"form.current_zone_service_fee"}})]}}])})],1),_c('mdb-col',{attrs:{"col":"6"}},[_c('validation-provider',{attrs:{"name":"Cross Fee","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('mdb-input',{attrs:{"type":"text","label":"Cross Fee","invalidFeedback":errors[0],"isValid":!!!errors[0],"validation":touched},model:{value:(_vm.form.cross_zone_service_fee),callback:function ($$v) {_vm.$set(_vm.form, "cross_zone_service_fee", $$v)},expression:"form.cross_zone_service_fee"}})]}}])})],1)],1),_c('mdb-row',[_c('mdb-col',{attrs:{"col":"12"}},[_c('validation-provider',{attrs:{"name":"Search KM","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('mdb-input',{attrs:{"type":"text","label":"Search KM","invalidFeedback":errors[0],"isValid":!!!errors[0],"validation":touched},model:{value:(_vm.form.search_km),callback:function ($$v) {_vm.$set(_vm.form, "search_km", $$v)},expression:"form.search_km"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }