export const APP_DEBUG = process.env.NODE_ENV == 'development' ? true : false
// export const APP_DEBUG=false;
export const APP_API_URL =
  process.env.NODE_ENV == 'development'
    ? 'http://localhost/ecare_api/public/api/'
    : 'https://ecareapi.hano.my/api/'
export const APP_FILE_URL =
  process.env.NODE_ENV == 'development'
    ? 'http:///localhost/ecare_api/public/'
    : 'https://ecareapi.hano.my/'
export const APP_STORAGE_URL =
  process.env.NODE_ENV == 'development'
    ? 'http:///localhost/ecare_api/public/storage/'
    : 'https://ecareapi.hano.my/storage/'
// export const APP_API_URL = 'https://ecareapi.hano.my/api/'
// export const APP_FILE_URL = 'https://ecareapi.hano.my/'
// export const APP_STORAGE_URL = 'https://ecareapi.hano.my/storage/'

if (!APP_DEBUG) {
  console.log('%cWARNING', 'color: #F44336; font-size: 3rem;')
  console.log(
    '%cThis is a browser feature intended for developers.Do NOT copy and paste something here if you do not understand it.',
    'color: #EF5350; font-size: 1.5rem;'
  )
}

export const APP_VERSION = 1
export const APP_VERSION_NAME = 'v1.0.0'
