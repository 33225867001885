<template>
  <div>
    <mdb-modal @shown="componentDidMount" :show="visible" @close="hideModal">
      <mdb-modal-header>
        <mdb-modal-title>{{id==0?"New Attribute":"Edit Attribute"}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
            <mdb-view :src="getAvatar()" alt="image" style="height: 200px; width: 200px;">
                <a @click="openFileSelect">
                    <mdb-mask overlay="white-slight"></mdb-mask>
                </a>
                <input multiple type="file" id="file-upload" @change="avatarUpload" hidden />
            </mdb-view>
            <mdb-input label="Attribute Name English" v-model="attribute_en" size="sm" />
            <mdb-input label="Attribute Name Chinese" v-model="attribute_cn" size="sm" />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-spinner v-if="loading" small />
        <mdb-btn  v-if="!loading" @click="submitCategory" color="success">Submit</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbInput, mdbSpinner, mdbView, mdbMask } from 'mdbvue';
  import {ajax, fileUploadAjax} from '../js/ajax';
    import {APP_FILE_URL} from '../js/config';

  export default {
    components: {
      mdbModal,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalBody,
      mdbModalFooter,
      mdbBtn, mdbInput, mdbSpinner, mdbView, mdbMask
    },
    props: ['visible', 'close', 'focus_attribute'],
    data: ()=>({
        id: 0,
        name:"",
        loading:true,
        attribute_en:'',
        attribute_cn:'',
        image: ""
    }),
    methods:{
        hideModal(){
            this.id=0;
            this.attribute_en='';
            this.attribute_cn='';
            this.image="";
            this.loading=true
            this.close();
        },
        componentDidMount(){
            if(this.focus_attribute){
                this.id=this.focus_attribute.id;
                this.attribute_en=this.focus_attribute.attribute_en;
                this.attribute_cn=this.focus_attribute.attribute_cn;
                this.image=this.focus_attribute.image;
            }
            this.loading=false;
        },
        submitCategory(){
            if(this.loading){
                return false;
            }
            this.loading=true;
            ajax({
                url: "admin/submit_attribute",
                data: {id: this.id, attribute_cn: this.attribute_cn, attribute_en: this.attribute_en, image: this.image},
                complete: ()=>this.loading=false,
                success: ()=>{
                    this.$notify.success({message: 'Home Care Attribute Submitted', position:'top right', timeOut: 5000});
                    this.hideModal();
                },
            }, this)
        },
        getAvatar(){
            if(!this.image){
                return require('@/assets/picture.png');
            }
            return APP_FILE_URL+this.image;
        },
        avatarUpload(event){
            fileUploadAjax({
                url: "admin/upload_home_care_avatar",
                data: {event: event.target.files, id: this.id},
                complete: ()=>this.action_loading=false,
                success: result=>{
                    this.image=result.data;
                },
                isShowAlert: true
            }, this)
        },
        openFileSelect(){
            document.getElementById("file-upload").click();
        },
    }
  };
</script>