<template>
    <div>
        <mdb-modal @shown="componentDidMount" :show="visible" @close="closeModal">
            <mdb-modal-header>
                <mdb-modal-title>{{ title }}</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <slot></slot>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn size="sm" :disabled="loading" @click="submitModal" color="primary">{{ submitLabel }}</mdb-btn>
                <mdb-btn size="sm" :disabled="loading" @click="onClose" outline="grey">Close
                </mdb-btn>
            </mdb-modal-footer>
            <Loading v-if="loading" />
        </mdb-modal>
    </div>
</template>

<script>
import {
    mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn
} from 'mdbvue';
import Loading from './Loading.vue'

export default {
    components: {
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbBtn,
        Loading
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        submitLabel: {
            type: String,
            required: true
        }
    },
    methods: {
        closeModal(payload) {
            if (payload) {
                return false
            }
            this.loading = false
            this.$emit('close')
        },
        submitModal() {
            this.$emit("onSubmitModal")
        },
        componentDidMount() {
            this.$emit("componentDidMount")
        },
        onClose() {
            this.loading = false
            this.$emit('close')
        }
    }
};
</script>