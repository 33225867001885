<template>
    <div>
        <Dialog :visible="visible" @close="closeModal" :title="title" :loading="loading" submitLabel="Submit"
            @onSubmitModal="submitAdvertismentPopup" @componentDidMount="componentDidMount">
            <validation-observer slim ref="form">
                <mdb-container>
                    <mdb-view style="height: 200px; width: 100%" class="mb-4">
                        <div class="promotion-img" v-bind:style="{ 'background-image': 'url(' + getImg() + ')' }">
                        </div>
                        <a @click="openFileSelect">
                            <mdb-mask overlay="white-slight"></mdb-mask>
                        </a>
                        <input multiple type="file" id="file-upload" @change="uploadImage" hidden />
                        <Loading v-if="upload_loading" />
                    </mdb-view>
                    <mdb-row>
                        <mdb-col col="6">
                            <validation-provider name="Name English" rules="required" v-slot="{ errors, touched }" slim>
                                <mdb-input type="text" v-model="form.name_en" label="Name English"
                                    :invalidFeedback="errors[0]" :isValid="!!!errors[0]" :validation="touched" />
                            </validation-provider>
                        </mdb-col>
                        <mdb-col col="6">
                            <validation-provider name="Name Chinese" rules="required" v-slot="{ errors, touched }" slim>
                                <mdb-input type="text" v-model="form.name_cn" label="Name Chinese"
                                    :invalidFeedback="errors[0]" :isValid="!!!errors[0]" :validation="touched" />
                            </validation-provider>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col col="12">
                            <validation-provider name="Description English" rules="required"
                                v-slot="{ errors, touched }" slim>
                                <mdb-input type="text" v-model="form.description_en" label="Description English"
                                    :invalidFeedback="errors[0]" :isValid="!!!errors[0]" :validation="touched" />
                            </validation-provider>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col col="12">
                            <validation-provider name="Description Chinese" rules="required"
                                v-slot="{ errors, touched }" slim>
                                <mdb-input type="text" v-model="form.description_cn" label="Description Chinese"
                                    :invalidFeedback="errors[0]" :isValid="!!!errors[0]" :validation="touched" />
                            </validation-provider>
                        </mdb-col>
                    </mdb-row>
                </mdb-container>
            </validation-observer>
        </Dialog>
    </div>
</template>

<script>
import {
    mdbContainer, mdbRow, mdbCol, mdbView, mdbMask,
    mdbInput
} from 'mdbvue';
import { APP_FILE_URL } from '../js/config';
import Dialog from './Dialog.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Loading from './Loading.vue'

export default {
    components: {
        mdbInput, mdbContainer, mdbRow, mdbCol, mdbView, mdbMask,
        Dialog, Loading, ValidationProvider, ValidationObserver
    },
    props: ['visible', 'close', "focus_promotion"],
    computed: {
        title() {
            return this.focus_promotion ? "Edit Promotion News" : "Add New Promotion News"
        }
    },
    data: () => ({
        form: {
            name_en: "",
            name_cn: "",
            description_en: "",
            description_cn: "",
            image: "",
            id: 0,
        },
        loading: false,
        upload_loading: false
    }),
    methods: {
        closeModal() {
            this.form = {
                name_en: "",
                name_cn: "",
                description_en: "",
                description_cn: "",
                image: "",
                id: 0,
            }
            this.upload_loading = false
            this.loading = false
            this.close()
        },
        componentDidMount() {
            if (this.focus_promotion) {
                this.form = { ...this.focus_promotion }
            }
        },
        getImg() {
            if (!this.form.image) {
                return require('@/assets/picture.png');
            }
            return APP_FILE_URL + this.form.image;
        },
        openFileSelect() {
            document.getElementById("file-upload").click();
        },
        uploadImage(event) {
            this.upload_loading = true
            this.$fileAjax({
                url: "admin/upload_banner_image",
                data: { event: event.target.files },
                complete: () => this.upload_loading = false,
                success: result => {
                    this.form.image = result.data;
                },
                isShowAlert: true
            }, this)
        },
        async submitAdvertismentPopup() {
            const success = await this.$refs.form.validate()
            if (!success) {
                this.$notify.error({ message: "Please Fill in all Required Fields", position: 'top right', timeOut: 5000 })
                return false
            }
            if (this.loading) {
                return false;
            }
            this.loading = true;
            this.$ajax({
                url: "admin/submit_promotion",
                data: this.form,
                complete: () => this.loading = false,
                success: () => {
                    this.$notify.success({ message: 'Promotion has been added.', position: 'top right', timeOut: 5000 });
                    this.$emit('success:callback')
                    this.close();
                },
            }, this)
        }
    }
};
</script>

<style scoped>
.promotion-img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    width: 100%;
}
</style>