<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Service Template List</h1>
            <hr>
            <h3 class="h3-responsive">Location Templates <mdb-btn class="btn-sm" @click="is_location_template = true"
                    color="success">Add Location Template</mdb-btn>
            </h3>
            <mdb-tbl>
                <mdb-tbl-head>
                    <tr>
                        <th width="3%">#</th>
                        <th>Name/Address</th>
                        <th>Location</th>
                        <th>Admin</th>
                        <th>Action</th>
                    </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                    <tr v-for="(location, index) in locations" v-bind:key="'location-' + location.id">
                        <td>{{ index + 1 }}</td>
                        <td>
                            <div class="font-weight-bold">{{ location.name }}</div>
                            <div>{{ location.address }}</div>
                        </td>
                        <td>
                            <div>{{ location.lat }}, {{ location.lng }}</div>
                            <div>
                                <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
                                    <span slot="tip">Google Map</span>
                                    <a slot="reference" target="_blank"
                                        :href="`https://www.google.com/maps?q=${location.lat},${location.lng}`">
                                        <i class="far fa-map"></i> Open Map
                                    </a>
                                </mdb-tooltip>
                            </div>
                        </td>
                        <td>{{ location.admin.username }}</td>
                        <td>
                            <mdb-btn class="btn-sm" color="info" @click="editLocation(location)">
                                <mdb-icon far icon="edit" />
                            </mdb-btn>
                            <mdb-btn class="btn-sm" color="danger" @click="deleteLocation(location)">
                                <mdb-icon far icon="trash-alt" />
                            </mdb-btn>
                        </td>
                    </tr>
                </mdb-tbl-body>
            </mdb-tbl>
            <hr />
            <h3 class="h3-responsive">Remark Templates <mdb-btn class="btn-sm" @click="is_remark_template = true"
                    color="success">Add Remark Template</mdb-btn>
            </h3>
            <mdb-tbl>
                <mdb-tbl-head>
                    <tr>
                        <th width="3%">#</th>
                        <th>Remark English / Remark Chinese</th>
                        <th>Admin</th>
                        <th>Action</th>
                    </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                    <tr v-for="(remark, index) in remarks" v-bind:key="'remark-' + remark.id">
                        <td>{{ index + 1 }}</td>
                        <td>
                            <div>{{ remark.remark_text_en }}</div>
                            <div>{{ remark.remark_text_cn }}</div>
                        </td>
                        <td>{{ remark.admin.username }}</td>
                        <td>
                            <mdb-btn class="btn-sm" color="info" @click="editRemark(remark)">
                                <mdb-icon far icon="edit" />
                            </mdb-btn>
                            <mdb-btn class="btn-sm" color="danger" @click="deleteRemark(remark)">
                                <mdb-icon far icon="trash-alt" />
                            </mdb-btn>
                        </td>
                    </tr>
                </mdb-tbl-body>
            </mdb-tbl>
            <LocationTemplateModal @success:callback="getData" :focus_location_template="focus_location_template"
                :visible="is_location_template" @close="closeModal" />
            <RemarkTemplateModal @success:callback="getData" :focus_remark_template="focus_remark_template"
                :visible="is_remark_template" @close="closeModal" />
            <ConfirmationModal
                :title="delete_type == 'LOCATION' ? 'Delete Location Template?' : 'Delete Remark Template?'"
                :visible="is_confirmation_modal" :close="closeModal" :submit="deleteTemplate" />

        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import { mdbContainer, mdbBtn, mdbTbl, mdbTblBody, mdbTblHead, mdbTooltip, mdbIcon } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import LocationTemplateModal from '../components/LocationTemplateModal'
import RemarkTemplateModal from '../components/RemarkTemplateModal'
import ConfirmationModal from '../components/ConfirmationModal';

export default {
    name: 'service_templates',
    components: {
        mdbContainer, LocationTemplateModal, mdbTooltip, mdbIcon, ConfirmationModal,
        AppNavigation, mdbBtn, mdbTbl, mdbTblBody, mdbTblHead, RemarkTemplateModal
    },
    data: () => ({
        is_location_template: false,
        focus_location_template: null,
        is_remark_template: false,
        focus_remark_template: null,
        is_confirmation_modal: false,

        loading: false,
        locations: [],
        remarks: [],
        delete_type: "",
    }),
    methods: {
        getData() {
            if (this.loading) {
                return false;
            }
            this.loading = true;
            this.$ajax({
                url: "admin/templates?service_id=1",
                method: "GET",
                complete: () => this.loading = false,
                success: result => {
                    this.locations = result.data.locations;
                    this.remarks = result.data.remarks;
                },
            }, this)
        },
        closeModal() {
            this.is_location_template = false;
            this.focus_location_template = null;
            this.is_remark_template = false;
            this.focus_remark_template = null;
            this.is_confirmation_modal = false;
        },
        editLocation(location) {
            this.is_location_template = true;
            this.focus_location_template = location;
        },
        editRemark(remark) {
            this.is_remark_template = true;
            this.focus_remark_template = remark;
        },
        deleteRemark(remark) {
            this.focus_remark_template = remark;
            this.is_confirmation_modal = true;
            this.delete_type = "REMARK";
        },
        deleteLocation(location) {
            this.focus_location_template = location;
            this.is_confirmation_modal = true;
            this.delete_type = 'LOCATION';
        },
        deleteTemplate() {
            const url = this.delete_type == 'LOCATION' ? `admin/location_template?id=${this.focus_location_template.id}` : `admin/service_remark_template?id=${this.focus_remark_template.id}`
            this.$ajax({
                url: url,
                method: "DELETE",
                complete: () => this.loading = false,
                success: () => {
                    this.$notify.success({ message: this.delete_type == 'LOCATION' ? "Location Template Deleted." : "Remark Template Deleted", position: 'top right', timeOut: 5000 });
                    this.getData();
                },
            }, this)
        }
    },
    mounted: function () {
        this.getData();
    }
}
</script>
