<template>
    <mdb-container fluid class="full-height login-background-image">
    <mdb-row>
      <mdb-col col="12">
        <mdb-container>
          <mdb-row>
            <mdb-col col="12" md="4" offsetMd="4" class="d-flex align-items-center justify-content-center full-height">
              <mdb-card style="width: 100%">
                <div class="d-flex justify-content-center align-items-center py-3">
                  <mdb-card-image :src="require('@/assets/logo.png')" style="height: 30vh; width: 30vh" alt="lapar logo"></mdb-card-image>
                </div>
                <mdb-card-body>
                  <mdb-card-title class="text-center">eCare Admin</mdb-card-title>
                  <mdb-input type="email" class="text-center" label="Username" v-model="username" />
                  <mdb-input @keydown.enter.native="onLogin()" type="password" class="text-center" label="Password" v-model="password" />
                  <div v-if="!loading" class="d-flex flex-center">
                    <mdb-btn @click="onLogin" color="primary">Login</mdb-btn>
                  </div>
                  <div v-if="loading" class="d-flex flex-center">
                    <mdb-spinner color="blue" />
                  </div>
                  <div class="text-center mt-2">{{version_name}}</div>
                </mdb-card-body>
              </mdb-card>
            </mdb-col>
          </mdb-row>
        </mdb-container>
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardBody, mdbCardTitle, mdbInput, mdbCardImage, mdbBtn, mdbSpinner  } from 'mdbvue';
import {ajax} from '../js/ajax';
import {APP_VERSION_NAME} from '../js/config';

export default {
  name: 'home',
  components: {
    mdbContainer,
    mdbRow, 
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbInput,
    mdbCardImage,
    mdbBtn,
    mdbSpinner
  },
  data: ()=>({
    username: "",
    password: "",
    loading: false,
    version_name: "",
    token: ""
  }),
  methods: {
    onLogin(){
      this.loading=true;
      ajax({
        url: "auth/login_admin",
        data: {username: this.username, password: this.password},
        complete: ()=>this.loading=false,
        success: result=>{
          this.$store.dispatch('setUser', result.data);
          this.$router.replace({ name: "Landing" })
        },
        fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
      })
    },
  },
  mounted: function(){    
    this.version_name=APP_VERSION_NAME;
  }
}
</script>

<style>
.full-height{
  height: 100vh;
}
.login-background-image{
  background-image: url("../assets/login_background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
