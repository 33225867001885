<template>
    <AppNavigation>
        <mdb-container fluid>
            <mdb-row>
              <mdb-col col="1" v-if="this.$route.params.id" class="d-flex flex-center">
                <mdb-btn @click="$router.back()" size="sm" color="primary"><i class="fas fa-arrow-left"></i></mdb-btn>
              </mdb-col>
              <mdb-col col="11">
                  <h1 class="h1-responsive">{{this.$route.params.id?"Edit Home Care":"Add New Home Care"}}</h1>
              </mdb-col>
            </mdb-row>
            <hr>
            <mdb-row class="mb-3">
            <mdb-col>
              <mdb-card>
                <mdb-card-body>
                  <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Basic Information</h4>
                  <mdb-view :src="getAvatar()" alt="image" style="height: 200px; width: 400px;">
                  <a @click="openFileSelect">
                      <mdb-mask overlay="white-slight"></mdb-mask>
                  </a>
                  <input multiple type="file" id="file-upload" @change="avatarUpload" hidden />
                  </mdb-view>
                  <mdb-row>
                    <mdb-col col="8">
                      <mdb-input v-model="name" label="Name" size="sm" />
                    </mdb-col>
                    <mdb-col col="4">
                        <mdb-input v-model="occupation" label="Occupation" size="sm" />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col col="3" class="d-flex flex-column justify-content-center">
                      <select v-model="gender" class="browser-default custom-select">
                        <option disabled value="" selected>Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                    </mdb-col>
                    <mdb-col col="3">
                      <mdb-input type="number" v-model="year_of_birth" label="Year of Birth" size="sm" />
                    </mdb-col>
                    <mdb-col col="3">
                      <mdb-input type="number" v-model="year_since" label="Year Since" size="sm" />
                    </mdb-col>
                    <mdb-col col="3">
                      <mdb-input type="number" v-model="price" label="Price/Hour" size="sm" />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col col="12">
                      <mdb-input v-model="description" type="textarea" label="Description" size="sm" />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col col="4">
                      <mdb-select multiple selectAll @getValue="selectLanguages" :options="languages" label="Language Spoken" />
                    </mdb-col>
                    <mdb-col col="4">
                      <mdb-input v-model="address" label="Address" size="sm" />
                    </mdb-col>
                    <mdb-col col="2">
                      <mdb-input v-model="lat" label="Lattitude" size="sm" />
                    </mdb-col>
                    <mdb-col col="2">
                      <mdb-input v-model="lng" label="Longitude" size="sm" />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col col="12">
                        <mdb-badge tag="a" @click.native="pushSelectAttribute(att)" class="mr-2" v-for="att in attributes" v-bind:key="att.id" :color="getSelectedBadgeColor(att)">{{att.attribute_en}}</mdb-badge>
                    </mdb-col>
                  </mdb-row>
                </mdb-card-body>
              </mdb-card>
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col col="12" class="mb-5">
                <mdb-card>
                    <mdb-card-body>
                        <mdb-spinner small v-if="loading" />
                        <mdb-btn v-if="!loading" color="primary" @click="submitProduct">Submit</mdb-btn>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
          </mdb-row>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer,mdbRow, mdbCol, mdbCard, mdbCardBody, mdbSelect,
  mdbView, mdbMask, mdbInput, mdbBtn, mdbSpinner, mdbBadge } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import { getColor } from '../js/colors';
import { APP_FILE_URL } from '../js/config';
import {fileUploadAjax, ajax} from '../js/ajax';

export default {
    name: 'submit_product',
    components: {
        mdbContainer,mdbRow, mdbCol, mdbCard, mdbCardBody, mdbBadge,
        mdbView, mdbMask, mdbInput, mdbBtn, mdbSpinner, mdbSelect,
        AppNavigation,
    },
    data: ()=>({
        loading: false,
        name: "",
        avatar: "",
        occupation: "",
        gender: "",
        language_spoken: "",
        description: "",
        price: "",
        year_of_birth: "",
        year_since: "",
        lat: "",
        lng: "",
        address: "",
        unit_no: "",
        attributes: [],
        selected_attributes: [],
        id: null,

        languages: [{
            text: 'English',
            value: "English"
          },{
            text: 'Chinese',
            value: "Chinese"
          },{
            text: 'Hokian',
            value: "Hokian"
          },{
            text: 'Cantonese',
            value: "Cantonese"
          },{
            text: 'Malay',
            value: 'Malay'
          }
        ]
    }),
    methods: {
        getColor(color){
            return getColor(color);
        },
        getAvatar(){
            if(!this.avatar){
                return require('@/assets/picture.png');
            }
            return APP_FILE_URL+this.avatar;
        },
        avatarUpload(event){
            fileUploadAjax({
                url: "admin/upload_home_care_avatar",
                data: {event: event.target.files, id: this.id},
                complete: ()=>this.action_loading=false,
                success: result=>{
                    this.avatar=result.data;
                },
                isShowAlert: true
            }, this)
        },
        openFileSelect(){
            document.getElementById("file-upload").click();
        },
        getData(){
          this.loading=true;
            ajax({
                url: "admin/get_home_care",
                data:{ id: this.id },
                complete: ()=>this.loading=false,
                success: result=>{
                    this.name=result.data.name;
                    this.avatar=result.data.avatar;
                    this.occupation= result.data.occupation;
                    this.gender= result.data.gender;
                    this.language_spoken= JSON.parse(result.data.language_spoken);
                    this.description= result.data.description;
                    this.price= result.data.price;
                    this.year_of_birth= result.data.year_of_birth;
                    this.year_since= result.data.year_since;
                    this.lat= result.data.lat;
                    this.lng= result.data.lng;
                    this.address= result.data.address;
                    this.unit_no= result.data.unit_no;
                    this.id= result.data.id;
                    var selected=[];
                    result.data.attributes.forEach(element => {
                        selected.push(element.id);
                    });
                    this.selected_attributes= selected;
                },
            }, this)
        },
        getAttributes(){
            this.loading=true;
            ajax({
                url: "admin/list_attributes",
                complete: ()=>this.loading=false,
                success: result=>{
                    this.attributes=result.data;
                },
            }, this)
        },
        close(){
            this.$router.push({ name: 'home_care_list' })
        },
        submitProduct(){
            if(this.loading){
                return false;
            }
            this.loading=true;
            ajax({
                url: "admin/submit_home_care",
                data: {
                    name: this.name,
                    avatar: this.avatar,
                    occupation: this.occupation,
                    gender: this.gender,
                    language_spoken: this.language_spoken,
                    description: this.description,
                    price: this.price,
                    year_of_birth: this.year_of_birth,
                    year_since: this.year_since,
                    lat: this.lat,
                    lng: this.lng,
                    address: this.address,
                    unit_no: this.unit_no,
                    selected_attributes: this.selected_attributes,
                    id: this.id,
                },
                complete: ()=>this.loading=false,
                success: ()=>{
                    this.$notify.success({message: this.name+' has been updated.', position:'top right', timeOut: 5000});
                    this.close();
                },
            }, this)
        },
        selectLanguages(language){
            this.language_spoken=language;
        },
        getSelectedBadgeColor(badge){
            var index=this.selected_attributes.findIndex(attribute=>{
                return attribute==badge.id;
            })
            if(index>=0){
                return 'primary'
            }else{
                return 'grey';
            }
        },
        pushSelectAttribute(att){
            var selected_attributes = [...this.selected_attributes];
            var index=selected_attributes.findIndex(attribute=>{
                return attribute==att.id;
            })
            if(index>=0){
                selected_attributes.splice(index, 1);
            }else{
                selected_attributes.push(att.id);
            }
            this.selected_attributes=selected_attributes;
        }
    },
    mounted: function(){
      this.getAttributes();
      if(this.$route.params.id){
        this.id=this.$route.params.id;
        this.getData();
      }
    }
}
</script>
