<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">App Home Configuration</h1>
            <hr>
            <h3 class="h3-responsive">Promotion Banner Popup <mdb-btn class="btn-sm"
                    @click="promotion_banner_modal = true" color="success">Add Promotion Banner</mdb-btn>
            </h3>
            <mdb-row>
                <mdb-col class="mb-2" md="3" v-for="(promo, index) in promotion_banners" :key="promo.id">
                    <div draggable="true" @dragstart="dragStart(index, 'BANNER')" @drop="dropHere(index, 'BANNER')"
                        @dragenter.prevent @dragover.prevent>
                        <mdb-card>
                            <div class="promotion-img"
                                v-bind:style="{ 'background-image': 'url(' + getImage(promo.image) + ')' }">
                                <div @click="toggleActive(promo, index)" class="custom-control custom-switch ml-1 mt-1">
                                    <input type="checkbox" class="custom-control-input" v-model="promo.is_active">
                                    <label class="custom-control-label"></label>
                                </div>
                            </div>
                            <mdb-card-body class="d-flex flex-column align-items-center">
                                <mdb-card-title class="text-center">{{ promo.name }}</mdb-card-title>
                                <mdb-card-text class="text-center" v-if="promo.media == 'SCREEN'">{{ promo.media }}:
                                    {{ promo.path }}</mdb-card-text>
                                <div class="d-flex flex-row justify-content-end">
                                    <mdb-btn class="btn-sm" color="info" @click="editBanner(promo)">
                                        <mdb-icon far icon="edit" />
                                    </mdb-btn>
                                    <mdb-btn class="btn-sm" color="primary" v-if="promo.media == 'URL'"
                                        @click="openUrl(promo.path)">
                                        <mdb-icon icon="globe" />
                                    </mdb-btn>
                                    <mdb-btn class="btn-sm" color="danger" @click="deleteBanner(promo)">
                                        <mdb-icon far icon="trash-alt" />
                                    </mdb-btn>
                                </div>
                            </mdb-card-body>
                        </mdb-card>
                    </div>
                </mdb-col>
            </mdb-row>
            <hr class="my-5" />
            <h3 class="h3-responsive">Promotion <mdb-btn class="btn-sm" @click="promotion_modal = true" color="success">
                    Add Promotion</mdb-btn>
            </h3>
            <mdb-row>
                <mdb-col class="mb-2" md="3" v-for="(promo, index) in promotions" :key="promo.id">
                    <div draggable="true" @dragstart="dragStart(index, 'PROMO')" @drop="dropHere(index, 'PROMO')"
                        @dragenter.prevent @dragover.prevent>
                        <mdb-card>
                            <div class="promotion-img"
                                v-bind:style="{ 'background-image': 'url(' + getImage(promo.image) + ')' }">
                                <div @click="togglePromoActive(promo, index)"
                                    class="custom-control custom-switch ml-1 mt-1">
                                    <input type="checkbox" class="custom-control-input" v-model="promo.is_active">
                                    <label class="custom-control-label"></label>
                                </div>
                            </div>
                            <mdb-card-body class="d-flex flex-column align-items-center">
                                <mdb-card-title class="text-center">{{ promo.name_en }}
                                </mdb-card-title>
                                <div class="d-flex flex-row justify-content-end">
                                    <mdb-btn class="btn-sm" color="info" @click="editPromo(promo)">
                                        <mdb-icon far icon="edit" />
                                    </mdb-btn>
                                    <mdb-btn class="btn-sm" color="danger" @click="deletePromo(promo)">
                                        <mdb-icon far icon="trash-alt" />
                                    </mdb-btn>
                                </div>
                            </mdb-card-body>
                        </mdb-card>
                    </div>
                </mdb-col>
            </mdb-row>
            <hr class="my-5" />
            <h3 class="h3-responsive">Videos <mdb-btn class="btn-sm" @click="is_video_modal = true" color="success">
                    Add Video</mdb-btn>
            </h3>
            <mdb-row>
                <mdb-col class="mb-2" md="3" v-for="(video, index) in videos" :key="video.id">
                    <div draggable="true" @dragstart="dragStart(index, 'VIDEO')" @drop="dropHere(index, 'VIDEO')"
                        @dragenter.prevent @dragover.prevent>
                        <mdb-card>
                            <div>
                                <video v-if="video.path" class="video-fluid z-depth-1" autoplay loop controls muted>
                                    <source :src="getVideo(video.path)" type="video/mp4" />
                                </video>
                                <div @click="toggleVideoActive(video, index)"
                                    class="custom-control custom-switch ml-1 mt-1"
                                    style="position: absolute; top: 0; left: 0;">
                                    <input type="checkbox" class="custom-control-input" v-model="video.is_active">
                                    <label class="custom-control-label"></label>
                                </div>
                            </div>
                            <mdb-card-body class="d-flex flex-column align-items-center">
                                <mdb-card-title class="text-center">{{ video.name }}
                                </mdb-card-title>
                                <div class="d-flex flex-row justify-content-end">
                                    <mdb-btn class="btn-sm" color="danger" @click="deleteVideo(video)">
                                        <mdb-icon far icon="trash-alt" />
                                    </mdb-btn>
                                </div>
                            </mdb-card-body>
                        </mdb-card>
                    </div>
                </mdb-col>
            </mdb-row>
        </mdb-container>
        <PromotionBanner @success:callback="getData" :focus_banner="focus_banner" :visible="promotion_banner_modal"
            :close="closeModal" />
        <PromotionModal :focus_promotion="focus_promotion" :visible="promotion_modal" :close="closeModal"
            @success:callback="getData" />
        <PromotionVideo :focus_video="focus_video" :visible="is_video_modal" :close="closeModal"
            @success:callback="getData" />
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import { mdbContainer, mdbRow, mdbCol, mdbBtn, mdbCard, mdbCardBody, mdbIcon, mdbCardTitle, mdbCardText } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import PromotionBanner from '../components/PromotionBanner'
import { APP_FILE_URL } from '../js/config';
import PromotionModal from '../components/PromotionModal.vue'
import PromotionVideo from '../components/PromotionVideo.vue'

export default {
    name: 'app_home_config',
    components: {
        mdbContainer, mdbRow, mdbCol, mdbBtn, mdbCard, mdbCardBody,
        AppNavigation, mdbIcon, mdbCardTitle, mdbCardText,
        PromotionBanner, PromotionModal, PromotionVideo
    },
    data: () => ({
        focus_banner: null,
        focus_promotion: null,
        focus_video: null,
        promotion_banner_modal: false,
        promotion_modal: false,
        is_video_modal: false,
        promotion_banners: [],
        promotions: [],
        videos: [],
        loading: false,

        drag_index: 0,
        drag_type: "",
    }),
    methods: {
        closeModal() {
            this.promotion_banner_modal = false;
            this.promotion_modal = false;
            this.is_video_modal = false
            this.focus_banner = null
            this.focus_promotion = null
            this.focus_video = null
        },
        getImage(img) {
            return APP_FILE_URL + img;
        },
        openUrl(url) {
            if (url) {
                window.open(url);
            }
            return null;
        },
        getData() {
            if (this.loading) {
                return false;
            }
            this.loading = true;
            this.$ajax({
                url: "admin/get_app_home_config",
                complete: () => this.loading = false,
                success: result => {
                    this.promotion_banners = result.data.banners;
                    this.promotions = result.data.promotions;
                    this.videos = result.data.videos
                },
            }, this)
        },
        toggleActive(banner, index) {
            if (this.action_loading) {
                return false;
            }
            let _temp = [...this.promotion_banners];
            _temp[index].is_active = _temp[index].is_active == 1 ? 0 : 1;
            this.promotion_banners = _temp;
            this.action_loading = true;
            this.$ajax({
                url: "admin/toggle_banner_active",
                data: { id: banner.id },
                complete: () => this.action_loading = false,
                success: () => {
                    this.$notify.success({ message: 'Banner Updated', position: 'top right', timeOut: 5000 });
                },
            }, this)
        },
        togglePromoActive(banner, index) {
            if (this.action_loading) {
                return false;
            }
            let _temp = [...this.promotions];
            _temp[index].is_active = _temp[index].is_active == 1 ? 0 : 1;
            this.promotions = _temp;
            this.action_loading = true;
            this.$ajax({
                url: "admin/toggle_promotion_active",
                data: { id: banner.id },
                complete: () => this.action_loading = false,
                success: () => {
                    this.$notify.success({ message: 'Promotion Updated', position: 'top right', timeOut: 5000 });
                },
            }, this)
        },
        toggleVideoActive(video, index) {
            if (this.action_loading) {
                return false;
            }
            let _temp = [...this.videos];
            _temp[index].is_active = _temp[index].is_active == 1 ? 0 : 1;
            this.videos = _temp;
            this.action_loading = true;
            this.$ajax({
                url: "admin/toggle_video_active",
                data: { id: video.id },
                complete: () => this.action_loading = false,
                success: () => {
                    this.$notify.success({ message: 'Video Updated', position: 'top right', timeOut: 5000 });
                },
            }, this)
        },
        editBanner(banner) {
            this.focus_banner = banner;
            this.promotion_banner_modal = true;
        },
        editPromo(promo) {
            this.focus_promotion = promo;
            this.promotion_modal = true;
        },
        deleteBanner(banner) {
            if (this.action_loading) {
                return false;
            }
            this.action_loading = true;
            this.$ajax({
                url: "admin/delete_banner",
                data: { id: banner.id },
                complete: () => this.action_loading = false,
                success: () => {
                    this.$notify.success({ message: 'Promotion Banner Deleted', position: 'top right', timeOut: 5000 });
                    this.getData();
                },
            }, this)
        },
        deletePromo(banner) {
            if (this.action_loading) {
                return false;
            }
            this.action_loading = true;
            this.$ajax({
                url: "admin/delete_promotion",
                data: { id: banner.id },
                complete: () => this.action_loading = false,
                success: () => {
                    this.$notify.success({ message: 'Promotion Banner Deleted', position: 'top right', timeOut: 5000 });
                    this.getData();
                },
            }, this)
        },
        dragStart(index, type) {
            this.drag_index = index;
            this.drag_type = type;
        },
        dropHere(index, type) {
            if (this.drag_type != type) {
                return false;
            }
            if (type == "BANNER") {
                let temp = this.promotion_banners[this.drag_index];
                let _banner = [...this.promotion_banners];
                _banner[this.drag_index] = _banner[index];
                _banner[index] = temp;
                this.promotion_banners = _banner;
                let data = [temp.id, _banner[this.drag_index].id];
                this.$ajax({
                    url: "admin/switch_banner_sequence",
                    data: { banner: data },
                    success: () => {
                        this.$notify.success({ message: 'Banner Sequence Updated', position: 'top right', timeOut: 5000 });
                    },
                }, this)
            } else
                if (type == "PROMO") {
                    let temp = this.promotions[this.drag_index];
                    let _banner = [...this.promotions];
                    _banner[this.drag_index] = _banner[index];
                    _banner[index] = temp;
                    this.promotions = _banner;
                    let data = [temp.id, _banner[this.drag_index].id];
                    this.$ajax({
                        url: "admin/switch_promotion_sequence",
                        data: { banner: data },
                        success: () => {
                            this.$notify.success({ message: 'Promotion Sequence Updated', position: 'top right', timeOut: 5000 });
                        },
                    }, this)
                } else
                    if (type == "VIDEO") {
                        let temp = this.videos[this.drag_index];
                        let _videos = [...this.videos];
                        _videos[this.drag_index] = _videos[index];
                        _videos[index] = temp;
                        this.videos = _videos;
                        let data = [temp.id, _videos[this.drag_index].id];
                        this.$ajax({
                            url: "admin/switch_video_sequence",
                            data: { video: data },
                            success: () => {
                                this.$notify.success({ message: 'Video Sequence Updated', position: 'top right', timeOut: 5000 });
                            },
                        }, this)
                    }
        },
        getVideo(video) {
            return APP_FILE_URL + video
        },
        deleteVideo(video) {
            if (this.action_loading) {
                return false;
            }
            this.action_loading = true;
            this.$ajax({
                url: "admin/delete_video",
                data: { id: video.id },
                complete: () => this.action_loading = false,
                success: () => {
                    this.$notify.success({ message: 'Video Deleted', position: 'top right', timeOut: 5000 });
                    this.getData();
                },
            }, this)
        }
    },
    mounted: function () {
        this.getData();
    }
}
</script>

<style scoped>
.promotion-img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    width: 100%;
    background-color: black;
}

.active-ad {
    background-color: #00C851;
    border-radius: 15px;
    height: 15px;
    width: 15px;
    margin: 10px
}

.inactive-ad {
    background-color: #ff4444;
    border-radius: 15px;
    height: 15px;
    width: 15px;
    margin: 10px
}
</style>