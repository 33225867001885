<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Permissions <mdb-btn @click="admin_modal=true" color="success" size="sm" class="my-0"><mdb-icon  icon="plus" /></mdb-btn></h1>
            <hr>
            <mdb-row>
                <mdb-col col="12">
                    <mdb-tbl responsive striped>
                        <mdb-tbl-head>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Slug</th>
                                <th>Permissions</th>
                            </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                            <tr v-for="(user, index) in users" :key="user.id">
                                <th scope="row">{{index+1}}</th>
                                <td>
                                    {{user.name}}
                                </td>
                                <td>
                                    {{user.slug}}
                                </td>
                                <td>
                                    {{user.value}}
                                </td>
                            </tr>
                        </mdb-tbl-body>
                    </mdb-tbl>
                </mdb-col>
            </mdb-row>
            <AdminPermissionModal :visible="admin_modal" :close="closeModal" :focus_permission="focus_permission"/>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn, mdbIcon } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {momentFormat16, momentFormat} from '../js/helpers';
// import {APP_FILE_URL} from '../js/config';
import AdminPermissionModal from '../components/AdminPermissionModal';

export default {
  name: 'permissions',
  components: {
    mdbContainer, mdbBtn,
    AppNavigation, mdbIcon, AdminPermissionModal,
    mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody
  },
  data: ()=>({
      users: [],
      loading:false,
      admin_modal: false,
      focus_permission: null
  }),
  methods: {
    closeModal(){
        this.focus_permission=null;
        this.admin_modal=false;
        this.getData();
    },

    getData(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/list_permissions",
            complete: ()=>this.loading=false,
            success: result=>{
                this.users=result.data;
            },
        }, this)
    },    
    momentFormat16(date){
        return momentFormat16(date);
    },
    momentFormat(date){
        return momentFormat(date);
    },
    editUser(user){
        this.focus_user=user;
        this.admin_modal=true;
    }
  },
  mounted: function(){
    this.getData();
  }
}
</script>
