import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
  })],
  state: {
    access_token: null,
    user: null,
    permissions: []
  },
  mutations: {
    setUser(state, payload){
      let user={
        id: payload.id,
        username: payload.username
      }
      state.user=user;
      state.access_token=payload.access_token;
    },
  },
  actions: {
    setUser(context, payload) {
      context.commit('setUser', payload)
    },
  }
})
