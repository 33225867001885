<template>
    <div>
        <Dialog :visible="visible" @close="closeModal" :title="title" :loading="loading" submitLabel="Submit"
            @onSubmitModal="submitVideo" @componentDidMount="componentDidMount">
            <validation-observer slim ref="form">
                <mdb-container>
                    <mdb-row>
                        <mdb-col col="12">
                            <video v-if="form.path" class="video-fluid z-depth-1" autoplay loop controls muted>
                                <source :src="getVideo()" type="video/mp4" />
                            </video>
                            <mdb-file-input @change.native="uploadVideo" sm btnColor="primary" accept="video/mp4"
                                btnTitle="Upload Video" />
                            <Loading v-if="upload_loading" />
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col col="12">
                            <validation-provider name="Name" rules="required" v-slot="{ errors, touched }" slim>
                                <mdb-input type="text" v-model="form.name" label="Name" :invalidFeedback="errors[0]"
                                    :isValid="!!!errors[0]" :validation="touched" />
                            </validation-provider>
                        </mdb-col>
                    </mdb-row>
                </mdb-container>
            </validation-observer>
        </Dialog>
    </div>
</template>

<script>
import {
    mdbContainer, mdbRow, mdbCol,
    mdbInput, mdbFileInput
} from 'mdbvue';
import { APP_FILE_URL } from '../js/config';
import Dialog from './Dialog.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Loading from './Loading.vue'

export default {
    components: {
        mdbInput, Dialog, mdbContainer, mdbRow, mdbCol, mdbFileInput, ValidationProvider, ValidationObserver, Loading
    },
    props: ['visible', 'close', "focus_video"],
    computed: {
        title() {
            return this.focus_video ? "Edit Video Display" : "Add New Video Display"
        }
    },
    data: () => ({
        form: {
            name: "",
            path: "",
            id: 0,
        },
        loading: false,
        upload_loading: false
    }),
    methods: {
        closeModal() {
            this.form = {
                name: "",
                path: "",
                id: 0,
            }
            this.loading = false;
            this.close();
        },
        componentDidMount() {
            if (this.focus_video) {
                this.form = { ...this.focus_video }
            }
        },
        getVideo() {
            return APP_FILE_URL + this.form.path;
        },
        openFileSelect() {
            document.getElementById("file-upload").click();
        },
        uploadVideo(event) {
            this.upload_loading = true
            this.$fileAjax({
                url: "admin/upload_promotion_video",
                data: { event: event.target.files },
                complete: () => this.upload_loading = false,
                success: result => {
                    this.form.path = result.data;
                },
                isShowAlert: true
            }, this)
        },
        async submitVideo() {
            const success = await this.$refs.form.validate()
            if (!success) {
                this.$notify.error({ message: "Please Fill in all Required Fields", position: 'top right', timeOut: 5000 })
                return false
            }
            if (this.loading) {
                return false;
            }
            this.loading = true;
            this.$ajax({
                url: "admin/submit_video",
                data: this.form,
                complete: () => this.loading = false,
                success: () => {
                    this.$notify.success({ message: 'Video has been added.', position: 'top right', timeOut: 5000 });
                    this.$emit('success:callback')
                    this.close();
                },
            }, this)
        }
    }
};
</script>

<style scoped>
.promotion-img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    width: 100%;
}
</style>