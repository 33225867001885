<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Product Management</h1>
            <hr>
            <mdb-row>
              <mdb-col col="12">
                <mdb-tbl responsive striped>
                  <mdb-tbl-head>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Information</th>
                      <th class="text-right">Action</th>
                    </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                    <tr v-for="(product, index) in products" :key="product.id">
                      <th scope="row">{{index+1}}</th>
                      <td>
                        <div class="d-flex flex-row">
                            <div class="d-flex flex-column align-items-center black" style="height: 100px; width: 150px; border-radius: 5px;">
                                <img :src="getProductAvatar(product.avatar)" class="waves-effect img-fluid" style="border-radius: 5px" />
                            </div>
                            
                            <div class="d-flex flex-column px-3">
                                <div class="font-weight-bold">{{product.name}}</div>
                                <div>{{product.description}}</div>
                                <div class="custom-control custom-switch" @click="toggleActive(product, index)">
                                  <input type="checkbox" class="custom-control-input" v-model="product.is_active">
                                  <label class="custom-control-label">Active</label>
                              </div>
                            </div>
                        </div>
                      </td>
                      <td>
                        <div>RM {{product.price}} - Stock: {{product.count_sale}}</div>
                        <div>RM {{product.price_day}}/day - Stock: {{product.count_rent}}</div>
                      </td>
                      <td class="text-right">
                        <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                          <span slot="tip">Edit Product</span>
                          <a @click="$router.push({ name: 'submit_product', params: {id: product.id} })" slot="reference">
                          <mdb-btn color="primary" size="sm"><mdb-icon far icon="edit" /></mdb-btn>
                          </a>
                        </mdb-tooltip>
                        <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                          <span slot="tip">Delete Product</span>
                          <a @click="showDeleteModal(product)" slot="reference">
                          <mdb-btn color="danger" size="sm"><mdb-icon icon="trash" /></mdb-btn>
                          </a>
                        </mdb-tooltip>
                      </td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </mdb-col>
            </mdb-row>
        </mdb-container>
        <ConfirmationModal  title="Are you sure?" :visible="show_confirmation_modal" :close="closeModal" :submit="deleteProduct" />
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn, mdbTooltip, mdbIcon } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import { APP_FILE_URL } from '../js/config';
import ConfirmationModal from '../components/ConfirmationModal';

export default {
  name: 'wallet_management',
  components: {
    mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn,
    AppNavigation, mdbTooltip, mdbIcon, ConfirmationModal
  },
  data: ()=>({
    loading:false,
    products: [],
    show_confirmation_modal: false,
    focus_product: null
  }),
  methods: {
    getData(){
      if(this.loading){
        return false;
      }
      this.loading=true;
      ajax({
        url: "admin/list_products",
        complete: ()=>this.loading=false,
        success: result=>{
          this.products=result.data;  
        },
      }, this)
    },
    getProductAvatar(avatar){
        return APP_FILE_URL+avatar;
    },
    deleteProduct(){
        ajax({
        url: "admin/delete_product",
        complete: ()=>this.loading=false,
        data: {id: this.focus_product.id},
        success: ()=>{
          this.$notify.success({message: "Product Deleted.", position:'top right', timeOut: 5000});
          this.getData();
        },
      }, this)
    },
    showDeleteModal(product){
        this.focus_product=product;
        this.show_confirmation_modal=true;
    },
    closeModal(){
        this.show_confirmation_modal=false;
    },
    toggleActive(product, index){  
        let temp=[...this.products];
        temp[index].is_active=temp[index].is_active==1?0:1;
        this.products=temp;
        ajax({
            url: "admin/toggle_product",
            complete: ()=>this.loading=false,
            data: {id: product.id},
            success: ()=>{
              this.$notify.success({message: "Product Updated.", position:'top right', timeOut: 5000});
              this.getData();
            },
        }, this)
    }
  },
  mounted: function(){
    this.getData();
  }
}
</script>
