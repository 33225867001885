import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login'
import Landing from './views/Landing'
import Admins from './views/Admins'
import AdminPermissions from './views/AdminPermissions'
import AdminRoles from './views/AdminRoles'
import Users from './views/Users'
import WalletManagement from './views/WalletManagement'
import ActiveOrders from './views/ActiveOrders'
import WalletTransactions from './views/WalletTransactions'
import AppHomeConfig from './views/AppHomeConfig'
import AppVersionControl from './views/AppVersionControl'
import SubmitProduct from './views/SubmitProduct'
import Products from './views/Products'
import ProductCategory from './views/ProductCategory'
import HomeCareList from './views/HomeCareList'
import SubmitHomeCare from './views/SubmitHomeCare'
import HomeCareAttributes from './views/HomeCareAttributes'
import StoreList from './views/StoreList'
import SubmitBookingStore from './views/SubmitBookingStore'
import ZoneCoverage from './views/ZoneCoverage'
import ServiceTemplates from './views/ServiceTemplates'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login,
    },
    {
      path: '/landing',
      name: 'Landing',
      component: Landing,
    },
    {
      path: '/admins',
      name: 'admins',
      component: Admins,
    },
    {
      path: '/permissions',
      name: 'permissions',
      component: AdminPermissions,
    },
    {
      path: '/roles',
      name: 'roles',
      component: AdminRoles,
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
    },
    {
      path: '/wallet_management',
      name: 'wallet_management',
      component: WalletManagement,
    },
    {
      path: '/active_orders',
      name: 'active_orders',
      component: ActiveOrders,
    },
    {
      path: '/wallet_transactions/:id?',
      name: 'wallet_transactions',
      component: WalletTransactions,
    },
    {
      path: '/app_home_config',
      name: 'app_home_config',
      component: AppHomeConfig,
    },
    {
      path: '/app_version_control',
      name: 'app_version_control',
      component: AppVersionControl,
    },
    {
      path: '/submit_product/:id?',
      name: 'submit_product',
      component: SubmitProduct,
    },
    {
      path: '/product_list',
      name: 'product_list',
      component: Products,
    },
    {
      path: '/product_category',
      name: 'product_category',
      component: ProductCategory,
    },
    {
      path: '/home_care_list',
      name: 'home_care_list',
      component: HomeCareList,
    },
    {
      path: '/submit_home_care/:id?',
      name: 'submit_home_care',
      component: SubmitHomeCare,
    },
    {
      path: '/home_care_attributes',
      name: 'home_care_attributes',
      component: HomeCareAttributes,
    },
    {
      path: '/booking_store_list',
      name: 'booking_store_list',
      component: StoreList,
    },
    {
      path: '/submit_booking_store',
      name: 'submit_booking_store',
      component: SubmitBookingStore,
    },
    {
      path: '/zone_coverage',
      name: 'zone_coverage',
      component: ZoneCoverage,
    },
    {
      path: '/service_templates',
      name: 'service_templates',
      component: ServiceTemplates,
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
