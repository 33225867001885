<template>
  <AppNavigation>
    <mdb-container fluid>
      <mdb-row>
        <mdb-col col="1" v-if="this.$route.params.id" class="d-flex flex-center">
          <mdb-btn @click="$router.back()" size="sm" color="primary"><i class="fas fa-arrow-left"></i></mdb-btn>
        </mdb-col>
        <mdb-col col="11">
          <h1 class="h1-responsive">{{ this.$route.params.id ? "Edit Product" : "Add New Product" }}</h1>
        </mdb-col>
      </mdb-row>
      <hr>
      <mdb-row class="mb-3">
        <mdb-col>
          <mdb-card>
            <mdb-card-body>
              <h4 class="h4-responsive" :style="{ color: getColor('APP_1') }">Product Information</h4>
              <mdb-row>
                <mdb-col md="4">
                  <mdb-file-input @change.native="avatarUpload" sm btnColor="primary" btn-title="Add Cover Photo"
                    :img="true" :src="getAvatar()" accept="image/jpeg, image/png, image/tiff" />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col col="1" v-for="(image, index) in images" v-bind:key="image">
                  <img :src="getPhoto(image)" class="img-fluid">
                  <mdb-btn size="sm" color="danger" @click="deleteImage(index)">
                    <mdb-icon far icon="trash-alt" />
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col col="12">
                  <mdb-file-input sm btnColor="primary" @change.native="additionalPhotoUpload"
                    btn-title="Add Additional Photo" accept="image/jpeg, image/png, image/tiff" />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col col="7">
                  <mdb-input v-model="name" label="Product Name" size="sm" />
                </mdb-col>
                <mdb-col col="2">
                  <mdb-input v-model="delivery_fee" label="Delivery Fee" size="sm" />
                </mdb-col>
                <mdb-col col="3" class="d-flex flex-column justify-content-center">
                  <select v-model="selected_category_id" class="browser-default custom-select">
                    <option value="0" disabled>Select Category</option>
                    <option :value="cate.id" v-for="cate in category" v-bind:key="cate.id">{{ cate.name_en }}</option>
                  </select>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col col="3">
                  <mdb-input type="number" v-model="price" label="Selling Price" size="sm" />
                </mdb-col>
                <mdb-col col="3">
                  <mdb-input type="number" v-model="count_sale" label="Sale Stock" size="sm" />
                </mdb-col>
                <mdb-col col="3">
                  <mdb-input type="number" v-model="price_day" label="Rent Price/Day" size="sm" />
                </mdb-col>
                <mdb-col col="3">
                  <mdb-input type="number" v-model="count_rent" label="Rent Stock" size="sm" />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col col="12">
                  <mdb-input v-model="description" label="Product Description" size="sm" />
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col col="12" class="mb-5">
          <mdb-card>
            <mdb-card-body>
              <mdb-spinner small v-if="loading" />
              <mdb-btn v-if="!loading" color="primary" @click="submitProduct">Submit</mdb-btn>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </mdb-container>
  </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {
  mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardBody, mdbFileInput, mdbInput, mdbBtn, mdbSpinner, mdbIcon
} from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import { getColor } from '../js/colors';
import { APP_FILE_URL } from '../js/config';
import { fileUploadAjax, ajax } from '../js/ajax';

export default {
  name: 'submit_product',
  components: {
    mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardBody, mdbInput, mdbBtn, mdbSpinner, mdbFileInput, mdbIcon,
    AppNavigation,
  },
  data: () => ({
    loading: false,
    name: "",
    avatar: "",
    price: "",
    price_day: "",
    description: "",
    sequence: "",
    delivery_fee: "",
    count_sale: "",
    count_rent: "",
    images: [],
    category: [],
    selected_category_id: 0,
    id: null
  }),
  methods: {
    getColor(color) {
      return getColor(color);
    },
    getAvatar() {
      if (!this.avatar) {
        return require('@/assets/picture.png');
      }
      return APP_FILE_URL + this.avatar;
    },
    avatarUpload(event) {
      fileUploadAjax({
        url: "admin/upload_product_avatar",
        data: { event: event.target.files, id: this.id },
        complete: () => this.action_loading = false,
        success: result => {
          this.avatar = result.data;
        },
        isShowAlert: true
      }, this)
    },
    additionalPhotoUpload(event) {
      fileUploadAjax({
        url: "admin/upload_product_avatar",
        data: { event: event.target.files, id: this.id },
        complete: () => this.action_loading = false,
        success: result => {
          console.log(this.images)
          this.images = [...this.images, result.data];
        },
        isShowAlert: true
      }, this)
    },
    openFileSelect() {
      document.getElementById("file-upload").click();
    },
    getPhoto(url) {
      return APP_FILE_URL + url;
    },
    deleteImage(index) {
      const images = [...this.images]
      images.splice(index, 1)
      this.images = images
    },
    getData() {
      this.loading = true;
      ajax({
        url: "admin/get_product",
        data: { id: this.id },
        complete: () => this.loading = false,
        success: result => {
          this.name = result.data.name;
          this.avatar = result.data.avatar;
          this.price = result.data.price;
          this.price_day = result.data.price_day;
          this.description = result.data.description;
          this.delivery_fee = result.data.delivery_fee;
          this.sequence = result.data.sequence;
          this.count_sale = result.data.count_sale;
          this.count_rent = result.data.count_rent;
          this.selected_category_id = result.data.category_id;
          this.images = result.data.images ? JSON.parse(result.data.images) : []
          this.id = result.data.id;
        },
      }, this)
    },
    getCategory() {
      this.loading = true;
      ajax({
        url: "admin/list_category",
        complete: () => this.loading = false,
        success: result => {
          this.category = result.data;
        },
      }, this)
    },
    close() {
      this.$router.push({ name: 'product_list' })
    },
    submitProduct() {
      if (this.loading) {
        return false;
      }
      this.loading = true;
      ajax({
        url: "admin/submit_product",
        data: {
          name: this.name,
          price: this.price,
          avatar: this.avatar,
          product_id: this.id,
          description: this.description,
          price_day: this.price_day,
          count_sale: this.count_sale,
          count_rent: this.count_rent,
          category_id: this.selected_category_id,
          delivery_fee: this.delivery_fee,
          images: JSON.stringify(this.images),
          id: this.id
        },
        complete: () => this.loading = false,
        success: () => {
          this.$notify.success({ message: this.name + ' has been updated.', position: 'top right', timeOut: 5000 });
          this.close();
        },
      }, this)
    }
  },
  mounted: function () {
    this.getCategory();
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getData();
    }
  }
}
</script>
