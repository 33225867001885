<template>
    <Dialog :visible="visible" @close="closeModal" :title="title" :loading="loading" submitLabel="Submit"
        @onSubmitModal="onSubmitZone" @componentDidMount="componentDidMount">
        <validation-observer slim ref="form">
            <mdb-container>
                <mdb-row>
                    <mdb-col col="12">
                        <validation-provider name="Zone Name" rules="required" v-slot="{ errors, touched }" slim>
                            <mdb-input type="text" v-model="form.name" label="Zone Name" :invalidFeedback="errors[0]"
                                :isValid="!!!errors[0]" :validation="touched" />
                        </validation-provider>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col col="6">
                        <validation-provider name="Operating Start" rules="required" v-slot="{ errors, touched }" slim>
                            <mdb-time-picker :hoursFormat="24" type="text" v-model="form.operating_start"
                                label="Operating Start" :invalidFeedback="errors[0]" :isValid="!!!errors[0]"
                                :validation="touched" />
                        </validation-provider>
                    </mdb-col>
                    <mdb-col col="6">
                        <validation-provider name="Operating End" rules="required" v-slot="{ errors, touched }" slim>
                            <mdb-time-picker :hoursFormat="24" type="text" v-model="form.operating_end"
                                label="Operating End" :invalidFeedback="errors[0]" :isValid="!!!errors[0]"
                                :validation="touched" />
                        </validation-provider>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col col="6">
                        <validation-provider name="Current Fee" rules="required" v-slot="{ errors, touched }" slim>
                            <mdb-input type="text" v-model="form.current_zone_service_fee" label="Current Fee"
                                :invalidFeedback="errors[0]" :isValid="!!!errors[0]" :validation="touched" />
                        </validation-provider>
                    </mdb-col>
                    <mdb-col col="6">
                        <validation-provider name="Cross Fee" rules="required" v-slot="{ errors, touched }" slim>
                            <mdb-input type="text" v-model="form.cross_zone_service_fee" label="Cross Fee"
                                :invalidFeedback="errors[0]" :isValid="!!!errors[0]" :validation="touched" />
                        </validation-provider>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col col="12">
                        <validation-provider name="Search KM" rules="required" v-slot="{ errors, touched }" slim>
                            <mdb-input type="text" v-model="form.search_km" label="Search KM"
                                :invalidFeedback="errors[0]" :isValid="!!!errors[0]" :validation="touched" />
                        </validation-provider>
                    </mdb-col>
                </mdb-row>
            </mdb-container>
        </validation-observer>
    </Dialog>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbTimePicker, mdbInput } from 'mdbvue';
import Dialog from './Dialog.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
    components: {
        Dialog, ValidationProvider, ValidationObserver,
        mdbContainer, mdbRow, mdbCol, mdbTimePicker, mdbInput
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        zone: {
            type: Object,
            default: null
        }
    },
    computed: {
        title() {
            return this.zone == null ? "Add New Zone" : "Edit Zone"
        }
    },
    data: () => ({
        loading: false,
        form: {
            name: "",
            operating_start: "",
            operating_end: "",
            cross_zone_service_fee: "",
            current_zone_service_fee: "",
            search_km: ""
        },
    }),
    methods: {
        closeModal() {
            this.form = {
                name: "",
                operating_start: "",
                operating_end: "",
                cross_zone_service_fee: "",
                current_zone_service_fee: "",
                search_km: ""
            }
            this.$emit('close')
        },
        componentDidMount() {
            if (this.zone) {
                this.form = { ...this.zone }
            }
        },
        async onSubmitZone() {
            const success = await this.$refs.form.validate()
            if (!success) {
                this.$notify.error({ message: "Please Fill in all Required Fields", position: 'top right', timeOut: 5000 })
                return false
            }
            this.loading = true;
            this.$ajax({
                url: "admin/zone",
                data: this.form,
                method: this.zone == null ? "POST" : "PUT",
                success: () => {
                    this.$notify.success({ message: "Zone Added", position: 'top right', timeOut: 5000 })
                    this.closeModal()
                    this.$emit('success:callback')
                },
                complete: () => this.loading = false,
                fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
            })
        },
    }
};
</script>