<template>
  <div>
    <mdb-modal scrollable size="lg" @shown="componentDidMount" :show="visible" @close="closeModal">
      <mdb-modal-header>
        <mdb-modal-title>Zone Coverage <mdb-btn @click="addCoverage" size="sm" v-if="!loading" color="primary">
            <mdb-icon icon="plus" />
          </mdb-btn>
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div id="mini-map"></div>
        <div class="text-small text-danger">Right Click to Delete Coverage</div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-spinner v-if="loading" />
        <mdb-btn @click="submitCoverage" v-if="!loading" color="primary">Submit Coverage</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <ConfirmationModal :visible="confirmation_modal" :close="closeModalComponent" title="Confirm Delete Coverage?"
      :submit="deleteZoneCoverage" />
  </div>
</template>
  
<script>
import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbSpinner, mdbIcon } from 'mdbvue';
import ConfirmationModal from '../components/ConfirmationModal';

export default {
  components: {
    mdbModal, mdbSpinner,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn, mdbIcon, ConfirmationModal
  },
  props: ['visible', 'close', "zone", "zones"],
  data: () => ({
    loading: false,
    id: 0,
    zone_coverage: [],
    map: null,
    markers: [],
    polygons: [],
    other_polygons: [],
    confirmation_modal: false,
    focus_coverage: {},
    focus_index: 0,
    local_zones: []
  }),
  methods: {
    closeModalComponent() {
      this.confirmation_modal = false;
    },
    componentDidMount() {
      this.zone_coverage = this.zone.zone_coverage;
      this.renderMap();
      let index = this.zones.findIndex((zone) => {
        return zone.id == this.zone.id;
      })
      let temp_zone = [...this.zones];
      temp_zone.splice(index, 1);
      this.local_zones = temp_zone;
      this.renderOtherCoverage();
    },
    closeModal() {
      this.map = null;
      this.close();
    },
    onClickPolygon(index) {
      this.focus_index = index;
      if (this.markers.length != 0) {
        this.markers[0].setMap(null);
        this.markers[1].setMap(null);
        this.markers[2].setMap(null);
        this.markers[3].setMap(null);
      }
      const google = window.google;
      var coverage = this.zone_coverage[index];
      var label_latlng1 = new google.maps.LatLng(coverage.lat_1, coverage.lng_1);
      var label_latlng2 = new google.maps.LatLng(coverage.lat_2, coverage.lng_2);
      var label_latlng3 = new google.maps.LatLng(coverage.lat_3, coverage.lng_3);
      var label_latlng4 = new google.maps.LatLng(coverage.lat_4, coverage.lng_4);
      this.markers[0] = new google.maps.Marker({
        position: label_latlng1,
        title: 'CoverageID: ' + coverage.id,
        draggable: true,
      });
      this.markers[1] = new google.maps.Marker({
        position: label_latlng2,
        title: 'CoverageID: ' + coverage.id,
        draggable: true,
      });
      this.markers[2] = new google.maps.Marker({
        position: label_latlng3,
        title: 'CoverageID: ' + coverage.id,
        draggable: true,
      });
      this.markers[3] = new google.maps.Marker({
        position: label_latlng4,
        title: 'CoverageID: ' + coverage.id,
        draggable: true,
      });
      this.markers[0].addListener('dragend', coord => {
        var laglng = { lat: parseFloat(coord.latLng.lat()).toFixed(6), lng: parseFloat(coord.latLng.lng()).toFixed(6) }
        this.ondragCoord(index, 0, laglng);
      });
      this.markers[1].addListener('dragend', coord => {
        var laglng = { lat: parseFloat(coord.latLng.lat()).toFixed(6), lng: parseFloat(coord.latLng.lng()).toFixed(6) }
        this.ondragCoord(index, 1, laglng);
      });
      this.markers[2].addListener('dragend', coord => {
        var laglng = { lat: parseFloat(coord.latLng.lat()).toFixed(6), lng: parseFloat(coord.latLng.lng()).toFixed(6) }
        this.ondragCoord(index, 2, laglng);
      });
      this.markers[3].addListener('dragend', coord => {
        var laglng = { lat: parseFloat(coord.latLng.lat()).toFixed(6), lng: parseFloat(coord.latLng.lng()).toFixed(6) }
        this.ondragCoord(index, 3, laglng);
      });
      this.markers[0].setMap(this.map);
      this.markers[1].setMap(this.map);
      this.markers[2].setMap(this.map);
      this.markers[3].setMap(this.map);
    },
    ondragCoord(index, sequence, latlng) {
      this.polygons[index].setMap(null);
      const google = window.google;
      const coverage_geolocation = [
        { lat: parseFloat(sequence == 0 ? latlng.lat : this.zone_coverage[index].lat_1), lng: parseFloat(sequence == 0 ? latlng.lng : this.zone_coverage[index].lng_1) },
        { lat: parseFloat(sequence == 1 ? latlng.lat : this.zone_coverage[index].lat_2), lng: parseFloat(sequence == 1 ? latlng.lng : this.zone_coverage[index].lng_2) },
        { lat: parseFloat(sequence == 2 ? latlng.lat : this.zone_coverage[index].lat_3), lng: parseFloat(sequence == 2 ? latlng.lng : this.zone_coverage[index].lng_3) },
        { lat: parseFloat(sequence == 3 ? latlng.lat : this.zone_coverage[index].lat_4), lng: parseFloat(sequence == 3 ? latlng.lng : this.zone_coverage[index].lng_4) },
      ];

      switch (sequence) {
        case 0: {
          this.zone_coverage[index].lat_1 = latlng.lat;
          this.zone_coverage[index].lng_1 = latlng.lng;
          break;
        }
        case 1: {
          this.zone_coverage[index].lat_2 = latlng.lat;
          this.zone_coverage[index].lng_2 = latlng.lng;
          break;
        }
        case 2: {
          this.zone_coverage[index].lat_3 = latlng.lat;
          this.zone_coverage[index].lng_3 = latlng.lng;
          break;
        }
        case 3: {
          this.zone_coverage[index].lat_4 = latlng.lat;
          this.zone_coverage[index].lng_4 = latlng.lng;
          break;
        }
      }
      this.polygons[index] = new google.maps.Polygon({
        paths: coverage_geolocation,
        strokeColor: this.zone.color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: this.zone.color,
        fillOpacity: 0.35,
      });
      google.maps.event.addListener(this.polygons[index], 'click', () => {
        this.onClickPolygon(index)
      });
      this.polygons[index].setMap(this.map);
    },
    onRightClickPolygon(index) {
      if (!this.zone_coverage[index].id) {
        this.zone_coverage.splice(index, 1);
        this.renderMap();
      } else {
        this.onClickPolygon(index);
        this.focus_coverage = this.zone_coverage[index];
        this.confirmation_modal = true;
      }
    },
    deleteZoneCoverage() {
      this.loading = true;
      this.$ajax({
        url: "admin/zone_coverage",
        method: "DELETE",
        data: { id: this.focus_coverage.id },
        success: () => {
          this.zone_coverage.splice(this.focus_index, 1);
          this.$notify.success({ message: this.zone.name + " Zone Coverage Updated", position: 'top right', timeOut: 5000 })
          this.renderMap()
        },
        complete: () => this.loading = false,
        fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
      })
    },
    addCoverage() {
      var lat = this.map.getCenter().lat();
      var lng = this.map.getCenter().lng();
      var coverage = {
        zone_id: this.zone.id,
        lat_1: lat - 0.01,
        lng_1: lng + 0.01,
        lat_2: lat - 0.01,
        lng_2: lng - 0.01,
        lat_3: lat + 0.01,
        lng_3: lng - 0.01,
        lat_4: lat + 0.01,
        lng_4: lng + 0.01,
      }
      this.zone_coverage.push(coverage);
      this.renderMap();
    },
    submitCoverage() {
      this.loading = true;
      this.$ajax({
        url: "admin/zone_coverage",
        data: {
          id: this.zone.id,
          zone_coverage: this.zone_coverage
        },
        success: () => {
          this.$notify.success({ message: this.zone.name + " Zone Coverage Updated", position: 'top right', timeOut: 5000 })
        },
        complete: () => this.loading = false,
        fail: fail => this.$notify.error({ message: fail.message, position: 'top right', timeOut: 5000 })
      })
    },
    renderMap() {
      const google = window.google;
      var lat = 5.365375;
      var lng = 100.301449;
      if (this.zone_coverage.length != 0) {
        lat = this.zone_coverage[0].lat_1;
        lng = this.zone_coverage[0].lng_1;
      }
      if (this.polygons.length != 0) {
        this.polygons.forEach(polygons => {
          polygons.setMap(null);
        });
      }
      if (this.markers.length != 0) {
        this.markers.forEach(marker => {
          marker.setMap(null);
        });
      }
      if (!this.map) {
        var mapProp = {
          center: new google.maps.LatLng(lat, lng),
          zoom: 12,
        };
        this.map = new google.maps.Map(document.getElementById("mini-map"), mapProp);
      }
      this.zone.zone_coverage.forEach((coverage, index) => {
        const coverage_geolocation = [
          { lat: parseFloat(coverage.lat_1), lng: parseFloat(coverage.lng_1) },
          { lat: parseFloat(coverage.lat_2), lng: parseFloat(coverage.lng_2) },
          { lat: parseFloat(coverage.lat_3), lng: parseFloat(coverage.lng_3) },
          { lat: parseFloat(coverage.lat_4), lng: parseFloat(coverage.lng_4) },
        ];
        // Construct the polygon.
        this.polygons[index] = new google.maps.Polygon({
          paths: coverage_geolocation,
          strokeColor: this.zone.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: this.zone.color,
          fillOpacity: 0.35,
        });
        google.maps.event.addListener(this.polygons[index], 'click', () => {
          this.onClickPolygon(index)
        });
        google.maps.event.addListener(this.polygons[index], 'contextmenu', () => {
          this.onRightClickPolygon(index)
        });
        this.polygons[index].setMap(this.map);
      })
    },
    renderOtherCoverage() {
      const google = window.google;
      let other_polygons = [];
      this.local_zones.forEach(zone => {
        zone.zone_coverage.forEach((coverage) => {
          const coverage_geolocation = [
            { lat: parseFloat(coverage.lat_1), lng: parseFloat(coverage.lng_1) },
            { lat: parseFloat(coverage.lat_2), lng: parseFloat(coverage.lng_2) },
            { lat: parseFloat(coverage.lat_3), lng: parseFloat(coverage.lng_3) },
            { lat: parseFloat(coverage.lat_4), lng: parseFloat(coverage.lng_4) },
          ];
          // console.log(index, google);
          // console.log(zone.name, coverage_geolocation)
          // Construct the polygon.
          other_polygons.push(new google.maps.Polygon({
            paths: coverage_geolocation,
            strokeColor: zone.color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: zone.color,
            fillOpacity: 0.35,
          }));
          this.other_polygons = other_polygons;
        });
      });
      this.other_polygons.forEach(coverage_polygon => {
        coverage_polygon.setMap(this.map);
      });
    }
  }
};
</script>
  
<style scoped>
#mini-map {
  height: 65vh;
  width: 100%;
}
</style>