<template>
    <AppNavigation>
        <mdb-container fluid>
            <mdb-row>
              <mdb-col col="1" v-if="this.$route.params.id" class="d-flex flex-center">
                <mdb-btn @click="$router.back()" size="sm" color="primary"><i class="fas fa-arrow-left"></i></mdb-btn>
              </mdb-col>
              <mdb-col col="11">
                  <h1 class="h1-responsive">{{this.$route.params.id?"Edit Store":"Add New Store"}}</h1>
              </mdb-col>
            </mdb-row>
            <hr>
            <mdb-row class="mb-3">
            <mdb-col>
              <mdb-card>
                <mdb-card-body>
                  <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Store Information</h4>
                  <mdb-view :src="getAvatar()" alt="image" style="height: 200px; width: 400px;">
                  <a @click="openFileSelect">
                      <mdb-mask overlay="white-slight"></mdb-mask>
                  </a>
                  <input multiple type="file" id="file-upload" @change="avatarUpload" hidden />
                  </mdb-view>
                  <mdb-row>
                    <mdb-col col="8">
                      <mdb-input v-model="name" label="Name" size="sm" />
                    </mdb-col>
                    <mdb-col col="4">
                        <mdb-input v-model="phone_number" label="phone_number" size="sm" />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col col="12">
                      <mdb-input v-model="description" type="textarea" label="Description" size="sm" />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col col="8">
                      <mdb-input v-model="address" label="Address" size="sm" />
                    </mdb-col>
                    <mdb-col col="2">
                      <mdb-input v-model="lat" label="Lattitude" size="sm" />
                    </mdb-col>
                    <mdb-col col="2">
                      <mdb-input v-model="lng" label="Longitude" size="sm" />
                    </mdb-col>
                  </mdb-row>
                </mdb-card-body>
              </mdb-card>
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col col="12" class="mb-5">
                <mdb-card>
                    <mdb-card-body>
                        <mdb-spinner small v-if="loading" />
                        <mdb-btn v-if="!loading" color="primary" @click="submitProduct">Submit</mdb-btn>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
          </mdb-row>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer,mdbRow, mdbCol, mdbCard, mdbCardBody,
  mdbView, mdbMask, mdbInput, mdbBtn, mdbSpinner } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import { getColor } from '../js/colors';
import { APP_FILE_URL } from '../js/config';
import {fileUploadAjax, ajax} from '../js/ajax';

export default {
    name: 'submit_booking_store',
    components: {
        mdbContainer,mdbRow, mdbCol, mdbCard, mdbCardBody,
        mdbView, mdbMask, mdbInput, mdbBtn, mdbSpinner,
        AppNavigation,
    },
    data: ()=>({
        loading: false,
        avatar: "",
        name: "",
        address: "",
        lat: "",
        lng: "",
        phone_number: "",
        description: "",
        id: null,
    }),
    methods: {
        getColor(color){
            return getColor(color);
        },
        getAvatar(){
            if(!this.avatar){
                return require('@/assets/picture.png');
            }
            return APP_FILE_URL+this.avatar;
        },
        avatarUpload(event){
            fileUploadAjax({
                url: "admin/upload_store_avatar",
                data: {event: event.target.files, id: this.id},
                complete: ()=>this.action_loading=false,
                success: result=>{
                    this.avatar=result.data;
                },
                isShowAlert: true
            }, this)
        },
        openFileSelect(){
            document.getElementById("file-upload").click();
        },
        getData(){
          this.loading=true;
            ajax({
                url: "admin/get_store",
                data:{ id: this.id },
                complete: ()=>this.loading=false,
                success: result=>{
                  this.name=result.data.name;
                  this.avatar=result.data.avatar;
                  this.address= result.data.address;
                  this.lat= result.data.lat;
                  this.lng= result.data.lng;
                  this.phone_number= result.data.phone_number;
                  this.description= result.data.description;
                  this.id= result.data.id;
                },
            }, this)
        },
        close(){
            this.$router.push({ name: 'booking_store_list' })
        },
        submitProduct(){
            if(this.loading){
                return false;
            }
            this.loading=true;
            ajax({
                url: "admin/submit_booking_store",
                data: {
                    name: this.name,
                    avatar: this.avatar,
                    address: this.address,
                    lat: this.lat,
                    lng: this.lng,
                    phone_number: this.phone_number,
                    description: this.description,
                    id: this.id
                },
                complete: ()=>this.loading=false,
                success: ()=>{
                    this.$notify.success({message: this.name+' has been updated.', position:'top right', timeOut: 5000});
                    this.close();
                },
            }, this)
        },
    },
    mounted: function(){
      if(this.$route.params.id){
        this.id=this.$route.params.id;
        this.getData();
      }
    }
}
</script>
